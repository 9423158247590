import React from "react";
import {observer} from "mobx-react";
import history from "../../helper/browserHistory";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {Link} from "react-router-dom";


@observer
class FooterComponent extends React.Component {

    render() {
        return (
            <Grid style={{fontSize:'12pt'}}>
                <Grid.Row className={"footer-top footer-top-padding"}>
                    <Grid.Column width={2} only={'computer'}/>
                    <Grid.Column computer={2} tablet={8} mobile={15}>
                        <Image src={"https://www.buergerstiftung-kreis-rv.de/images/ibs.png"}
                               width={157}
                               height={157}
                               fluid
                               alt={"Logo IBS"}
                        />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={15} style={{paddingLeft: "20px"}}>
                        <h3>Bürgerstiftung Kreis Ravensburg trägt das Gütesiegel des Bundesverbandes
                            Deutscher Stiftungen</h3>
                        <p>Das Gütesiegel wird an Bürgerstiftungen verliehen, deren Satzungen die
                            "<p className={"top-footer-link"}
                                onClick={() => {
                                    window.open('https://www.buergerstiftungen.org/de/buergerstiftungen/10-merkmale/', "_blank")
                                }}>10 Merkmale einer Bürgerstiftung</p>"
                            erfüllen. Es hat sich über die Jahre erfolgreich als Qualitätsstandard der
                            Bürgerstiftungsbewegung etabliert.
                        </p>
                    </Grid.Column>
                    <Grid.Column computer={2} tablet={8} mobile={15} textAlign={"center"}>
                        <Image src={"https://www.buergerstiftung-kreis-rv.de/images/logo__bvds.svg"}
                               fluid
                               width={157}
                               height={58}
                               alt={"Stiftungen logo"}
                               style={{cursor: "pointer"}}
                               onClick={() => {
                                   window.open('https://www.stiftungen.org', "_blank")
                               }}
                        />
                        <p style={{paddingTop: "10px"}}>
                            Die Bürgerstiftung Kreis Ravensburg ist seit Januar 2006 Mitglied
                        </p>
                        <Image
                            src={"https://www.buergerstiftung-kreis-rv.de/images/Transparente_ZivilgesellschaftPNG.png"}
                            fluid
                            width={157}
                            height={58}
                            alt={"ITZ logo"}
                            style={{
                                cursor: "pointer",
                                background: "white",
                                padding: "8px",
                                borderRadius: "9px"
                            }}
                            onClick={() => {
                                window.open('https://www.bskrv.de/transparenz', "_blank")
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={2} only={'computer'}/>
                </Grid.Row>
                <Grid.Row className={"footer-top blue-shadow"} centered>
                    <Grid.Column computer={4} tablet={8} mobile={15}>
                        <Button circular fluid className={"call-to-action-button-white"}
                                onClick={() => {
                                    window.open('https://www.youtube.com/watch?v=pygdWKj7bn8', "_blank")
                                }}>
                            MEHR ERFAHREN <Icon name={"play circle outline"}
                                                style={{"color": "#004e84", marginLeft: "10px"}}/>
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className={"footer footer-top-padding"}>
                    <Grid.Column width={2} only={'computer'}/>
                    <Grid.Column computer={3} tablet={8} mobile={16}>
                        <Image src={"https://www.buergerstiftung-kreis-rv.de/images/Logo.svg"}
                               onClick={() => {
                                   history.push("/");
                                   this.setState({});
                               }}
                               width={200}
                               alt={"Bürgerstiftung Kreis Ravensburg Logo"}
                        />
                        <br/>
                        <Image src={"/images/SAB_Benchmark_Logo_ReportBS2023_web.png"}
                               width={200}
                               alt={"Logo SAB"}
                        />
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={5} mobile={15} style={{paddingLeft: "20px"}}>
                        <p>
                        Bürgerstiftung Kreis Ravensburg <br/>
                            Stiftungsbüro<br/>
                            Marktstraße 22<br/>
                            88212 Ravensburg<br/>
                        </p>
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={8} mobile={15} style={{paddingLeft: "20px"}}>
                        <p>
                            Telefon <a rel={'noreferrer'} href={'tel:075135912943'}>0751 35 912 943</a> <br/>
                           E-Mail <a rel={'noreferrer'} href={'mailto:info@bskrv.de'}>info@bskrv.de</a>
                        </p>
                        <Icon
                            name={"youtube play"}
                            size={"big"}
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                window.open('https://www.youtube.com/watch?v=jNCucblUpMQ', "_blank")
                            }}/>
                        <Icon
                            name={"facebook"}
                            size={"big"}
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                window.open('https://www.facebook.com/buergerstiftungkreisrv/', "_blank")
                            }}/>
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={8} mobile={16}
                                 style={{paddingLeft: "20px", paddingRight: "20px"}}>
                        <p>
                            Bankverbindung<br/>
                            Kreissparkasse Ravensburg<br/>
                            IBAN DE24 6505 0110 0101 0202 02<br/>
                            BIC SOLADES1RVB<br/>
                        </p>

                        <Button circular fluid className={"call-to-action-button-blue"}
                                onClick={() => {
                                    history.push("/donate?iframe-donationform-default");
                                    this.setState({});
                                }}>
                            DIREKT SPENDEN <Icon name={"arrow right"} inverted/>
                        </Button>
                    </Grid.Column>
                    <Grid.Column width={2} only={'computer'}/>
                </Grid.Row>
                <Grid.Row className={"footer"} centered>
                    <Grid.Column width={2} only={'computer'}/>
                    <Grid.Column width={5} only={'computer'}/>
                    <Grid.Column computer={16} tablet={16} mobile={16} textAlign={'center'}
                                 style={{wordBreak: "break-word", paddingTop: "20px"}}>
                        <Link
                            to={"/contact"}
                            className={"footer-link"}>
                            KONTAKT
                        </Link>
                        <p className={"footer-link-separator"}>
                            |
                        </p>
                        <Link
                            to={"/statute"}
                            className={"footer-link"}>
                            SATZUNG
                        </Link>
                        <p className={"footer-link-separator"}>
                            |
                        </p>
                        <Link
                            to={"/dataProtection"}
                            className={"footer-link"}>
                            DATENSCHUTZ
                        </Link>
                        <p className={"footer-link-separator"}>
                            |
                        </p>
                        <Link
                            to={"/imprint"}
                            className={"footer-link"}>
                            IMPRESSUM
                        </Link>
                        <p className={"footer-link-separator"}>
                            |
                        </p>
                        <Link
                            to={"/download"}
                            className={"footer-link"}>
                            DOWNLOADS
                        </Link>
                        <p className={"footer-link-separator"}>
                            |
                        </p>
                        <Link
                            to={"/transparenz"}
                            className={"footer-link"}>
                            TRANSPARENZ
                        </Link>
                        <p className={"footer-link-separator"}>
                            |
                        </p>
                        {/*
                        <p
                            onClick={() => {
                                hashHistory.push("/press");
                                this.setState({});
                            }}
                            className={"footer-link"}>
                            PRESSE
                        </p>
                        <p className={"footer-link-separator"}>
                            |
                        </p>
                        */}
                        <p
                            onClick={() => {
                                history.push("/admin-login");
                                this.setState({});
                            }}
                            className={"footer-link"}>
                            LOGIN
                        </p>
                    </Grid.Column>
                    <Grid.Column width={2} only={'computer'}/>
                    <Grid.Column width={16} >
                        <p style={{textAlign: 'center',marginTop:'10px'}}>
                            {"©" + new Date().getFullYear() + " Bürgerstiftung Kreis Ravensburg"}
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default FooterComponent