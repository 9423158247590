import React, {Suspense} from "react"
import ReactDOM from "react-dom"
import history from "./helper/browserHistory"
import {Router} from "react-router-dom";
import {Provider} from "mobx-react";
import {HelmetProvider} from 'react-helmet-async';
import initStores from "./stores/initStores";
import App from "./components/App"

import './stylesheets/_all.scss';
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

let fetch = null;
if (typeof window !== "undefined") {
    fetch = require('whatwg-fetch')
}
const stores = initStores(window.__SERVER_DATA__.store);

export const main = () => {
    return (
        ReactDOM.hydrate(
            <Suspense
                fallback={<Button style={{margin: "auto", display: "contents"}} content={"Loading..."} loading basic
                                  size={"massive"}/>}>
                <HelmetProvider>
                    <Router history={history}>
                        <Provider stores={stores}>
                            <App/>
                        </Provider>
                    </Router>
                </HelmetProvider>
            </Suspense>,
            document.getElementById('root')
        ));
};
