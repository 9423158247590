import config from "../../src/config/main.config";
import browserHistory from "../../src/helper/browserHistory";
const fetch = require('node-fetch');

export function fetchTextById(frontendId) {
    return fetch(config.BASE_URL + "texts/" + frontendId, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json().then(json => {
                    return addObjectToTexts(json);

                });

            }
        })
        .catch(
            error => {
                console.log(error);
            }
        );
}

function addObjectToTexts(textObject) {
    let tempTexts = {}
    if (typeof tempTexts[textObject.frontendId] === "undefined") {
        tempTexts[textObject.frontendId] = {};
    }

    tempTexts[textObject.frontendId][textObject.language] = {
        content: textObject.content,
        title: textObject.title,
        description: textObject.description,
        isNamespace: textObject.namespace
    };
    return tempTexts
}


export function loadTextsByNamespace(baseURL, namespace) {
    return fetch(baseURL + "texts/namespace/" + namespace, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'pragma': 'no-cache',
            'cache-control': 'no-cache',
            'Access-Control-Allow-Origin': '*'
        }
    }).then((response) => {
        if (response.status >= 200 && response.status < 400) {
           return response.json().then(json => {
                return json;
            });
        } else {
            return {
                error: "400"
            }
        }

    }).catch(error => {
        console.log(error);
        return {
            error: "400"
        }
    });
}

export function loadTexts(baseURL) {
    return fetch(baseURL + "/api/texts/all", {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'pragma': 'no-cache',
            'cache-control': 'no-cache',
            'Access-Control-Allow-Origin': '*'
        }
    }).then((response) => {
        if (response.status >= 200 && response.status < 400) {
            return response.json();
        } else {
            return {
                error: "400"
            }
        }

    }).catch(error => {
        console.log(error)
        return {
            error: "400"
        }
    });
}

export function loadContentWithFrontendIds(baseURL, frontendIds) {

    let preparedFrontendIds = "";

    for (let i = 0; i < frontendIds.length; i++) {
        if (i === 0) {
            preparedFrontendIds += "frontendIds=" + encodeURI(frontendIds[i])
        } else {
            preparedFrontendIds += "&frontendIds=" + encodeURI(frontendIds[i])
        }
    }

    return fetch(baseURL + "texts/all?" + preparedFrontendIds, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'pragma': 'no-cache',
            'cache-control': 'no-cache',
            'Access-Control-Allow-Origin': '*'
        }
    }).then((response) => {
        if (response.status >= 200 && response.status < 400) {
            return response.json().then(result => {
                return {
                    texts: result
                }
            }).catch((error) => {
                return {
                    error: "400"
                }
            });
        } else {
            return {
                error: "400"
            }
        }

    }).catch(error => {
        return {
            error: "400"
        }
    });
}

export function loadCurrent(baseURL) {
    //Get all ingredients
    return fetch(baseURL + "blog/amount-articles/0", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json().then(result => {
                return {
                    dataForPage: result.articles
                }
            }).catch((error) => {
                return {
                    error: "400"
                }
            });
        } else {
            //console.log(response.status)
        }
    }).catch(
        error => {
            //console.log(error);
            if (error.status === 401) {
                browserHistory.replace("/");
            }
        }
    );
}

export function loadArticle(data, baseURL) {
    const {id} = data.params;
    return new Promise(resolve=>fetch(baseURL + 'blog/article/' + id, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json().then(result => {
                return resolve({
                    dataForPage: result
                })
            }).catch((error) => {
                return {
                    error: "400"
                }
            });
        } else {
            //console.log(response.status)
        }
    }).catch(
        error => {
            //console.log(error);
            if (error.status === 401) {
                browserHistory.replace("/");
            }
        }
    ));
}
