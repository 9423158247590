import React from "react";
import EditHeaderComponent from "../moap-components/EditHeaderComponent";
import PropTypes from "prop-types";
import EditTextButtonComponent from "../moap-components/EditTextButtonComponent";

import {observer} from "mobx-react";
import userStore from "../../stores/userStore";
import {createText, getTokenFromLocalStorage, updateText} from "../../helper/util";
import config from "../../config/main.config";
import {fetchTextById} from "../../../server/helper/ssrHelper";
import EditTextComponent from "../moap-components/EditTextComponent"

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";


@observer
class TitleImageComponent extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            textStore: this.props.textStore,
            editState: false,
            editValue: ""
        }
    }

    componentDidMount() {
        let ids = [this.props.imageLinkId, this.props.headerId, this.props.subheaderId];
        for (let i = 0; i < ids.length; i++) {
            if (typeof this.props.textStore[ids[i]] === "undefined") {
                fetchTextById(ids[i]).then(text => {
                    if (typeof text !== "undefined") {
                        this.setState(prevState => ({
                            ...prevState,
                            textStore: {...prevState.textStore, [ids[i]]: text[ids[i]]}
                        }));
                    }
                })
            }
        }
    }


    handleChange(event, data) {
        this.setState({...this.state, editValue: data.value});
    }

    uploadThumbnailImage(event) {
        let self = this;
        const data = new FormData();
        data.append('image', event.target.files[0]);

        fetch(config.BASE_URL + 'images', {
            method: "POST",
            body: data,
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    console.log("response json: " + json.toString());
                    self.setState({...self.state, editValue: json.link});
                });

            }
        }).catch((error) => {
            console.log(error)
        });
    }

    render() {
        const defaultImage = "https://www.buergerstiftung-kreis-rv.de/images/Buergerstiftung.jpg";
        const {
            headerId,
            buttonTextId,
            subheaderId,
            buttonLinkId,
            imageLinkId,
            separatorLink,
            separatorHref
        } = this.props;
        if (!headerId || !subheaderId || !imageLinkId) {
            console.log('Src is not acceptable');
            return null;
        }
        const textStore = this.state.textStore;
        let textToShow = typeof textStore[imageLinkId] === "undefined" ? {content: ""} : textStore[imageLinkId]["de-DE"];
        //  let style = {backgroundImage: "linear-gradient(225deg, rgba(0, 78, 132, 0.24), #004e84), url('" + textToShow.content + "')"};
        // if (textToShow.content === "") {
        let style = {background: "linear-gradient(225deg, rgba(0, 78, 132, 0.24), #004e84), url('" + defaultImage + "')"};
        //  }

        let displayType = userStore.userFromServer !== null ? "admin" : "user";

        let editDiv = <div/>;
        if (displayType === "admin") {
            if (this.state.editState) {
                editDiv = <div>
                    <Form>
                        <Form.Input value={this.state.editValue} onChange={this.handleChange.bind(this)}/>
                    </Form>
                    <br/>
                    <input type="file" id="file" className="inputfile"
                           onChange={this.uploadThumbnailImage.bind(this)}
                           accept="image/*"/>
                    <label htmlFor="file" className={"input-label"}>
                        Hochladen
                    </label>

                    <Button.Group>
                        <Button color="green" onClick={() => {
                            let textToSend = {
                                content: this.state.editValue,
                                title: "",
                                description: ""
                            };
                            if (typeof textStore[imageLinkId] === "undefined" || typeof textStore[imageLinkId]["de-DE"] === "undefined") {
                                createText(imageLinkId, textToSend, "de-DE", this.props.namespace).then(isSuccess => {
                                    if (isSuccess) {
                                        fetchTextById(imageLinkId).then(text => {
                                            textStore[imageLinkId] = text[imageLinkId]
                                            this.setState({...this.state, editState: false, editValue: ""});
                                        })
                                    }
                                }).catch(() => {

                                });
                            } else {
                                updateText(imageLinkId, textToSend, "de-DE", this.props.namespace).then(isSuccess => {
                                    if (isSuccess) {
                                        fetchTextById(imageLinkId).then(text => {
                                            textStore[imageLinkId] = text[imageLinkId]
                                            this.setState({...this.state, editState: false, editValue: ""});
                                        })
                                    }
                                });
                            }

                        }}>Speichern</Button>
                        <Button color="red" onClick={() => {
                            this.setState({...this.state, editState: false});
                        }}>Abbrechen</Button>

                    </Button.Group>
                </div>
            } else {
                editDiv = <div>
                    {textToShow.content}
                    {textToShow.isNamespace === this.props.namespace ? "" :
                        <Label size={"tiny"} className={"namespace-note"} style={{opacity: "80%"}} floating
                               color={"red"}>!</Label>}
                    <br/>
                    <Button color="orange" icon="edit outline" onClick={() => {
                        this.setState({...this.state, editState: true, editValue: textToShow.content});
                    }}/>
                </div>
            }
        }


        return (
            <Grid.Row
                centered
                className={displayType !== "admin" ? "component-title-image-small" : "component-title-image-large"}
                style={style}>
                <Grid.Column width={12} textAlign={"center"} verticalAlign={"middle"}>
                    {editDiv}
                    {separatorHref ?
                        <Image
                            centered
                            src={separatorLink}
                            size={"tiny"}
                            href={separatorHref}
                        />
                        :
                        <Image
                            centered
                            src={separatorLink}
                            size={"tiny"}
                        />
                    }
                    <EditHeaderComponent textStore={this.state.textStore} id={headerId}
                                         namespace={this.props.namespace}/>
                    <h3><EditTextComponent textStore={this.state.textStore} id={subheaderId}
                                           namespace={this.props.namespace}/></h3>
                    {!buttonLinkId || !buttonTextId || buttonLinkId.length === 0 || buttonTextId.length === 0 ?
                        null
                        :
                        <EditTextButtonComponent textId={buttonTextId} linkTextId={buttonLinkId}
                                                 textStore={this.state.textStore}
                                                 namespace={this.props.namespace}
                                                 cssClass={"call-to-action-button"}
                                                 style={{paddingTop: "40px"}}/>
                    }
                </Grid.Column>

            </Grid.Row>
        )
    }
}

TitleImageComponent.propTypes = {
    textStore: PropTypes.object,
    headerId: PropTypes.string,
    buttonTextId: PropTypes.string,
    subheaderId: PropTypes.string,
    buttonLinkId: PropTypes.string,
    imageLinkId: PropTypes.string,
    small: PropTypes.bool,
    separatorLink: PropTypes.string,
    namespace: PropTypes.string,
    separatorHref: PropTypes.string
};

TitleImageComponent.defaultProps = {
    textStore: {},
    namespace: null,
    headerId: null,
    buttonTextId: null,
    subheaderId: null,
    buttonLinkId: null,
    imageLinkId: null,
    small: false,
    separatorLink: "https://www.buergerstiftung-kreis-rv.de/images/seperator.png",
    separatorHref: null,
};

export default TitleImageComponent