import React from "react";
import {observer} from "mobx-react";
import {isAdmin, removeTokenFromStorage} from "../../helper/util";
import userStore from "../../stores/userStore";
import history from "../../helper/browserHistory";
import {HamburgerSqueeze} from 'react-animated-burgers'
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import {Link} from "react-router-dom";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu"
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

@observer
class NavigationBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            windowWidth: "",
            search: "",
            modalOpen: false,
            showMenu: false,
            activeElement: "none"
        }
    }


    componentDidMount() {
        this.setActive();
        history.listen(this.setActive.bind(this));
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', this.updateDimensions);
        }
        this.updateDimensions();
    }

    componentWillUnmount() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.updateDimensions);
        }
    }

    updateDimensions = () => {
        this.setState({
            ...this.state,
            windowWidth: window.innerWidth
        });
    };

    doSearch() {
        history.push({
            pathname: '/search',
            search: this.state.search
        })
        this.setState({...this.state, modalOpen: false})
    }

    setActive() {
        if (typeof window !== "undefined") {
            switch (window.location.pathname) {
                case "/unsere_schwerpunkte":
                    this.setState({...this.state, activeElement: "/unsere_schwerpunkte"})
                    break;
                case "/current":
                    this.setState({...this.state, activeElement: "/current"})
                    break;
                case "/promotions":
                    this.setState({...this.state, activeElement: "/promotions"})
                    break;
                case "/engage":
                    this.setState({...this.state, activeElement: "/engage"})
                    break;
                case "/partner":
                    this.setState({...this.state, activeElement: "/partner"})
                    break;
                case "/about":
                    this.setState({...this.state, activeElement: "/about"})
                    break;
                default:
                    this.setState({...this.state, activeElement: "none"})
                    break;
            }
        }
    }


    handleChange(event, data) {
        this.setState({...this.state, search: data.value});
    }

    render() {
        const defaultImage = "https://www.buergerstiftung-kreis-rv.de/images/Buergerstiftung.jpg";
        const displayType = isAdmin() ? "admin" : "user";
        return (
            <Grid>
                <div style={{width: "100%"}}>
                    {displayType === "admin" ?
                        <Grid.Row>
                            <Grid.Column floated='right' computer={9} tablet={13} mobile={13}>
                                <Segment className={"navigation-menu-segment"}>
                                    <Menu key="menu" pointing secondary stackable className={"navigation-menu"}
                                          style={{backgroundColor: "#00a5dd", paddingRight: "20px"}}>
                                        <Menu.Menu position='right'>
                                            <Menu.Item
                                                style={{margin: "auto", paddingRight: "20px"}}
                                                active={this.state.activeElement === "/spendensammlung-admin"}
                                                onClick={() => {
                                                    history.push("/spendensammlung-admin");
                                                    this.setState({});
                                                }}
                                                key="spendensammlung-admin">
                                                Spendensammlungen
                                            </Menu.Item>
                                            <Menu.Item
                                                style={{margin: "auto", paddingRight: "20px"}}
                                                active={this.state.activeElement === "/blog-author-overview"}
                                                onClick={() => {
                                                    history.push("/blog-author-overview");
                                                    this.setState({});
                                                }}
                                                key="blog-author-overview">
                                                Profil
                                            </Menu.Item>
                                            <Menu.Item
                                                style={{margin: "auto", paddingRight: "20px"}}
                                                active={this.state.activeElement === "/blog-management"}
                                                onClick={() => {
                                                    history.push("/blog-management");
                                                    this.setState({});
                                                }}
                                                key="blog-management">
                                                Verwaltung
                                            </Menu.Item>
                                            <Menu.Item
                                                style={{margin: "auto", paddingRight: "20px"}}
                                                position='right'
                                                onClick={() => {
                                                    removeTokenFromStorage();
                                                    userStore.user = null;
                                                    this.setState({...this.state})
                                                    history.go(0)
                                                }}
                                                exact
                                                active={true}
                                                key="logout">
                                                Logout
                                            </Menu.Item>
                                        </Menu.Menu>
                                    </Menu>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column width={2} only={'computer'}/>
                        </Grid.Row>
                        :
                        null
                    }
                </div>
                {this.state.windowWidth > 995 ?
                    <Responsive as={Grid.Row} minWidth={995} className={"navigation-top-row"}>
                        <Grid.Column width={2} only={'computer'}/>
                        <Grid.Column computer={2} tablet={8} mobile={14} verticalAlign={"middle"}>
                            <img src={"https://www.buergerstiftung-kreis-rv.de/images/Logo.svg"}
                                 width={157}
                                 height={111}
                                 onClick={() => {
                                     history.push("/");
                                     this.setState({});
                                 }}
                                 style={{cursor: 'pointer'}}
                                 alt={"Bürgerstiftung Kreis Ravensburg Logo"}
                            />
                        </Grid.Column>
                        <Grid.Column computer={10} tablet={13} mobile={13}>
                            <Grid style={{margin: 0, marginTop: '1rem'}}>
                                <Grid.Row style={{padding: 0}}>
                                    <Grid.Column floated='right' computer={6} tablet={8} mobile={14}>
                                        <Button circular fluid className={"call-to-action-button"}
                                                style={{marginBottom: '1rem'}}
                                                onClick={() => {
                                                    history.push("/donate");
                                                    this.setState({});
                                                }}>
                                            <Link
                                                to={"/donate"}>
                                                DIREKT SPENDEN
                                            </Link>
                                            <Image
                                                width={22}
                                                height={22}
                                                src={"https://www.buergerstiftung-kreis-rv.de/images/iconset/white/Arrowwhite.svg"}
                                                alt={"Logo Pfeil-Button "}
                                                className={"button-arrow-icon"}
                                            />
                                            {/*
                                          <Icon name={"arrow right"} inverted/>
                                       */}

                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{"paddingBottom": "0px"}}>
                                    <Grid.Column floated='right'>
                                        <Segment className={"navigation-menu-segment"}>
                                            <Menu key="menu" pointing secondary stackable className={"navigation-menu"}>
                                                <Menu.Menu position='right'>
                                                    <Menu.Item
                                                        active={this.state.activeElement === "/current"}
                                                        onClick={() => {
                                                            history.push("/current");
                                                            this.setState({});
                                                        }}
                                                        className={this.state.activeElement === "/current" ? "nav-item-green" : "nav-item"}
                                                        key="current">
                                                        AKTUELLES
                                                    </Menu.Item>
                                                    <Menu.Item
                                                        active={this.state.activeElement === "/promotions"}
                                                        onClick={() => {
                                                            history.push("/promotions");
                                                            this.setState({});
                                                        }}
                                                        className={this.state.activeElement === "/promotions" ? "nav-item-orange" : "nav-item"}
                                                        key="promotions">
                                                        FÖRDERUNG
                                                    </Menu.Item>
                                                    <Menu.Item
                                                        active={this.state.activeElement === "/unsere_schwerpunkte"}
                                                        onClick={() => {
                                                            history.push("/unsere_schwerpunkte");
                                                            this.setState({});
                                                        }}
                                                        className={this.state.activeElement === "/unsere_schwerpunkte" ? "nav-item-blue" : "nav-item"}
                                                        key="home">
                                                        PROJEKTE
                                                    </Menu.Item>
                                                    <Menu.Item
                                                        active={this.state.activeElement === "/engage"}
                                                        onClick={() => {
                                                            history.push("/engage");
                                                            this.setState({});
                                                        }}
                                                        className={this.state.activeElement === "/engage" ? "nav-item-red" : "nav-item"}
                                                        key="engage">
                                                        {"Mitwirken".toUpperCase()}
                                                    </Menu.Item>
                                                    <Menu.Item
                                                        active={this.state.activeElement === "/partner"}
                                                        onClick={() => {
                                                            history.push("/partner");
                                                            this.setState({});
                                                        }}
                                                        className={this.state.activeElement === "/partner" ? "nav-item-yellow" : "nav-item"}
                                                        key="partner">
                                                        PARTNER
                                                    </Menu.Item>
                                                    <Menu.Item
                                                        active={this.state.activeElement === "/about"}
                                                        onClick={() => {
                                                            history.push("/about");
                                                            this.setState({});
                                                        }}
                                                        className={this.state.activeElement === "/about" ? "nav-item-yellow" : "nav-item"}
                                                        key="about">
                                                        ÜBER UNS
                                                    </Menu.Item>
                                                </Menu.Menu>
                                                <Menu.Item className={"nav-item"}>
                                                    <Modal trigger={<Icon name={"search"}
                                                                          onClick={() => this.setState({
                                                                              ...this.state,
                                                                              modalOpen: true
                                                                          })}/>}
                                                           basic size='small' dimmer='blurring'
                                                           className={"modal-margin"}
                                                           open={this.state.modalOpen}>
                                                        <Modal.Header content='Wonach suchen Sie?'/>
                                                        <Modal.Content>
                                                            <Form onSubmit={() => this.doSearch()}>
                                                                <Form.Input icon={<Icon name={"search"} link
                                                                                        onClick={() => this.doSearch()}/>}
                                                                            placeholder='Suchen...'
                                                                            fluid size='massive'
                                                                            className={"search-bar"}
                                                                            value={this.state.search}
                                                                            onChange={this.handleChange.bind(this)}/>
                                                            </Form>
                                                        </Modal.Content>
                                                    </Modal>
                                                </Menu.Item>
                                            </Menu>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column width={2} only={'computer'}/>
                    </Responsive> :
                    <Responsive as={Grid.Row} maxWidth={991} style={{paddingBottom: "0px"}}>
                        <Grid.Column tablet={5} mobile={6} verticalAlign={"middle"}>
                            <Image src={"https://www.buergerstiftung-kreis-rv.de/images/Logo.svg"}
                                   onClick={() => {
                                       history.push("/");
                                       this.setState({});
                                   }}
                                   style={{height: "auto"}}
                                   alt={"Bürgerstiftung Kreis Ravensburg Logo"}
                            />
                        </Grid.Column>
                        <Grid.Column tablet={11} mobile={10} stretched>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={10} floated={"right"} textAlign={"right"}>
                                        <Modal trigger={<Image
                                            spaced
                                            src={"https://www.buergerstiftung-kreis-rv.de/images/iconset/blue/Searchblue.svg"}
                                            alt={"Suchsymbol"}
                                            className={"mobile-search"}
                                            onClick={() => this.setState({...this.state, modalOpen: true})}/>}
                                               basic size='small' dimmer='blurring'
                                               className={"modal-margin"}
                                               open={this.state.modalOpen}>
                                            <Modal.Header content='Wonach suchen Sie?'/>
                                            <Modal.Content>
                                                <Form onSubmit={() => this.doSearch()}>
                                                    <Form.Input icon={<Icon name={"search"} link
                                                                            onClick={() => this.doSearch()}/>}
                                                                placeholder='Suchen...'
                                                                fluid size='massive'
                                                                className={"search-bar"}
                                                                value={this.state.search}
                                                                onChange={this.handleChange.bind(this)}/>
                                                </Form>
                                            </Modal.Content>
                                        </Modal>
                                        <HamburgerSqueeze
                                            isActive={this.state.showMenu}
                                            buttonWidth={50}
                                            layerSpacing={150}
                                            barColor="#004e84"
                                            onClick={() => this.setState(prevState => ({
                                                ...this.state,
                                                showMenu: !prevState.showMenu
                                            }))}/>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{paddingRight: "33px", paddingTop: "0px"}} verticalAlign={"bottom"}
                                          stretched>
                                    <Grid.Column floated='right' width={16} style={{paddingRight: "0"}}>
                                        <Button circular fluid className={"call-to-action-button"}
                                                style={{marginBottom: '1rem'}}
                                                onClick={() => {
                                                    history.push("/donate");
                                                    this.setState({});
                                                }}>
                                            <Link to={"/donate"}>
                                                DIREKT SPENDEN
                                            </Link>
                                            <Icon name={"arrow right"} inverted/>
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <div className={this.state.showMenu ? "mobile-menu-container" : "inputfile"}
                             style={{width: "100%"}}>
                            <Grid.Column width={16}
                                         className={"mobile-menu-column"}
                                         style={{background: "linear-gradient(225deg, rgba(0, 78, 132, 0.24), #004e84), url('" + defaultImage + "')"}}>
                                <Segment className={"mobile-navigation-menu-segment"}>
                                    <Menu key="menu" fluid vertical className={"navigation-menu-mobile"}>
                                        <Menu.Menu position='right'>
                                            <Menu.Item
                                                active={this.state.activeElement === "/current"}
                                                onClick={() => {
                                                    history.push("/current");
                                                    this.setState({
                                                        ...this.state,
                                                        showMenu: false
                                                    })
                                                }}
                                                href={"/current"}
                                                className={this.state.activeElement === "/current" ? "nav-item-green-mobile" : "nav-item-mobile"}
                                                key="current">
                                                AKTUELLES
                                            </Menu.Item>
                                            <div className={"mobile-menu-divider"}>
                                                <div/>
                                            </div>
                                            <Menu.Item
                                                active={this.state.activeElement === "/promotions"}
                                                onClick={() => {
                                                    history.push("/promotions");
                                                    this.setState({
                                                        ...this.state,
                                                        showMenu: false
                                                    })
                                                }}
                                                href={"/promotions"}
                                                className={this.state.activeElement === "/promotions" ? "nav-item-orange-mobile" : "nav-item-mobile"}
                                                key="promotions">
                                                FÖRDERUNG
                                            </Menu.Item>
                                            <div className={"mobile-menu-divider"}>
                                                <div/>
                                            </div>
                                            <Menu.Item
                                                active={this.state.activeElement === "/unsere_schwerpunkte"}
                                                onClick={() => {
                                                    history.push("/unsere_schwerpunkte");
                                                    this.setState({
                                                        ...this.state,
                                                        showMenu: false
                                                    })
                                                }}
                                                href={"/unsere_schwerpunkte"}
                                                className={this.state.activeElement === "/unsere_schwerpunkte" ? "nav-item-blue-mobile" : "nav-item-mobile"}
                                                key="current">
                                                PROJEKTE
                                            </Menu.Item>
                                            <div className={"mobile-menu-divider"}>
                                                <div/>
                                            </div>
                                            <Menu.Item
                                                active={this.state.activeElement === "/engage"}
                                                onClick={() => {
                                                    history.push("/engage");
                                                    this.setState({
                                                        ...this.state,
                                                        showMenu: false
                                                    })
                                                }}
                                                href={"/engage"}
                                                className={this.state.activeElement === "/engage" ? "nav-item-red-mobile" : "nav-item-mobile"}
                                                key="engage">
                                                {"Mitwirken".toUpperCase()}
                                            </Menu.Item>
                                            <div className={"mobile-menu-divider"}>
                                                <div/>
                                            </div>
                                            <Menu.Item
                                                active={this.state.activeElement === "/partner"}
                                                onClick={() => {
                                                    history.push("/partner");
                                                    this.setState({});
                                                }}
                                                className={this.state.activeElement === "/partner" ? "nav-item-yellow" : "nav-item-mobile"}
                                                key="partner">
                                                PARTNER
                                            </Menu.Item>
                                            <div className={"mobile-menu-divider"}>
                                                <div/>
                                            </div>
                                            <Menu.Item
                                                active={this.state.activeElement === "/about"}
                                                onClick={() => {
                                                    history.push("/about");
                                                    this.setState({
                                                        ...this.state,
                                                        showMenu: false
                                                    })
                                                }}
                                                href={"/about"}
                                                className={this.state.activeElement === "/about" ? "nav-item-yellow-mobile" : "nav-item-mobile"}
                                                key="about">
                                                ÜBER UNS
                                            </Menu.Item>
                                        </Menu.Menu>
                                    </Menu>
                                </Segment>
                            </Grid.Column>
                        </div>
                    </Responsive>}
            </Grid>

        );
    }
}

export default NavigationBar