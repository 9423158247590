import React from "react";
import PropTypes from "prop-types";
import {observer} from "mobx-react";
import {withTranslation} from "react-i18next";
import Loadable from 'react-loadable';
import LoaderComponent from "../LoaderComponent";
import {isAdmin} from "../../helper/util";
import {fetchTextById} from "../../../server/helper/ssrHelper";

const TextStoreHTMLEditor = Loadable({
    loader: () => import(/* webpackChunkName: 'TextStoreHTMLEditor' */ "./TextStoreHTMLEditor"),
    loading: () => LoaderComponent
});


@observer
class EditHTMLComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            textStore: this.props.textStore,
        }
    }

    componentDidMount() {
        if (typeof this.props.textStore[this.props.id] === "undefined") {
            fetchTextById(this.props.id).then(text => {
                if (typeof text !== "undefined") {
                    this.setState({...this.state, textStore: {[this.props.id]: text[this.props.id]}})
                }
            })
        }
    }

    render() {
        const textStore = this.state.textStore;
        let {id} = this.props;
        // TODO: Make also to const this is only for test purpose set to let
        let displayType = isAdmin() ? "admin" : "user";

        let textToShow = {content: ""};
        let contentToShow = "";

        if (displayType === "admin" && typeof document !== "undefined") {
            contentToShow =
                <TextStoreHTMLEditor textStore={this.props.textStore} id={id} namespace={this.props.namespace}/>
        } else {
            let language = 'de-DE';

            if (this.props.i18n && typeof this.props.i18n.language !== "undefined") {
                language = this.props.i18n.language;
            }

            if (typeof textStore[id] !== "undefined") {
                if (typeof textStore[id][language] !== "undefined") {
                    textToShow = textStore[id][language];
                } else {
                    textToShow = {content: ""};
                }
            }

            contentToShow = <div id={id} dangerouslySetInnerHTML={{__html: textToShow.content}}/>;

        }
        return (
            contentToShow
        );
    }
}

EditHTMLComponent.propTypes = {
    textStore: PropTypes.object,
    namespace: PropTypes.string,
    id: PropTypes.string,
};

EditHTMLComponent.defaultProps = {
    textStore: {},
    namespace: null,
    id: Math.random().toString()
};

export default withTranslation()(EditHTMLComponent)
