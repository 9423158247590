import {observable, action, toJS} from 'mobx';
import config from "../config/main.config";
import {getTokenFromLocalStorage} from "../helper/util";
import fetch from "node-fetch";


class TextStore {
    @observable texts = {};
    @observable loading = false;
    @observable error = false;

    constructor(stores) {

        if(typeof window !== "undefined"){
            this.texts = stores.textStore.texts;
        }else{
            this.addElementsOfArrayToTexts(stores);
        }
    }

    @action fetchTexts() {
        this.loading = true;
        return fetch(config.BASE_URL + "texts/all", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.addElementsOfArrayToTexts(json);
                    });

                } else if (response.status === 401) {
                    alert("Bitte melden Sie sich an");
                } else {
                    alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
                    this.error = "Error on fetching";
                    return false;
                }
            })
            .catch(
                error => {
                    alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    @action fetchTextById(frontendId) {
        this.loading = true;
        return fetch(config.BASE_URL + "texts/" + frontendId, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.addObjectToTexts(json);
                    });

                } else if (response.status === 401) {
                    alert("Bitte melden Sie sich an");
                } else {
                    alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
                    this.error = "Error on fetching";
                    return false;
                }
            })
            .catch(
                error => {
                    alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    @action createText(id, textToCreate, language, namespace) {
        return fetch(config.BASE_URL + "texts/", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                frontendId: id,
                content: textToCreate.content,
                title: textToCreate.title,
                description: textToCreate.description,
                language: language,
                namespace: namespace,
            })
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.addObjectToTexts(json);
                    });
                    return true;
                } else if (response.status === 401) {
                    alert("Bitte melden Sie sich an");
                } else {
                    alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
                    this.error = "Error on fetching";
                    return false;
                }
            })
            .catch(
                error => {
                    alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
                    this.error = "Error on fetching";
                    return false;
                }
            );
    }

    addElementsOfArrayToTexts(textArray) {
        let tempTexts = toJS(this.texts);
        for (let i = 0; i < textArray.length; i++) {
            let objectToAddOrOverwrite = textArray[i];
            if (typeof tempTexts[objectToAddOrOverwrite.frontendId] === "undefined") {
                tempTexts[objectToAddOrOverwrite.frontendId] = {};
            }

            tempTexts[objectToAddOrOverwrite.frontendId][objectToAddOrOverwrite.language] = {
                content: objectToAddOrOverwrite.content,
                title: objectToAddOrOverwrite.title,
                description: objectToAddOrOverwrite.description
            };
        }
        this.texts = tempTexts;
    }

    addObjectToTexts(textObject) {
        let tempTexts = toJS(this.texts);
        if (typeof tempTexts[textObject.frontendId] === "undefined") {
            tempTexts[textObject.frontendId] = {};
        }

        tempTexts[textObject.frontendId][textObject.language] = {
            content: textObject.content,
            title: textObject.title,
            description: textObject.description
        };

        this.texts = tempTexts;
    }

    @action updateText(id, textToUpdate, language, namespace) {
        return fetch(config.BASE_URL + "texts", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                frontendId: id,
                content: textToUpdate.content,
                title: textToUpdate.title,
                description: textToUpdate.description,
                language: language,
                namespace: namespace
            })
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    this.addObjectToTexts({
                        frontendId: id,
                        content: textToUpdate.content,
                        title: textToUpdate.title,
                        description: textToUpdate.description,
                        language: language
                    });

                    return true;
                } else if (response.status === 401) {
                    alert("Bitte melden Sie sich an");
                } else {
                    alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
                    this.error = "Error on fetching";
                    return false;
                }
            })
            .catch(
                error => {
                    alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
                    this.error = "Error on fetching";
                    return false;
                }
            );
    }
}

export default TextStore;