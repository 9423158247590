import React from "react";
import PropTypes from "prop-types";
import {observer} from "mobx-react";
import {observable} from "mobx";
import userStore from "../../stores/userStore"
import {fetchTextById} from "../../../server/helper/ssrHelper";
import {createText, updateText} from "../../helper/util";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";

@observer
class EditSimpleValueComponent extends React.Component {
    @observable language = "de-DE";

    constructor(props) {
        super(props);
        this.state = {
            textStore: this.props.textStore,
            editState: false,
            text: ""
        }
    }

    componentDidMount() {
        if (typeof this.props.textStore[this.props.id] === "undefined") {
            fetchTextById(this.props.id).then(text => {
                if (typeof text !== "undefined") {
                    this.setState({...this.state, textStore: {[this.props.id]: text[this.props.id]}})
                }
            })
        }
    }


    handleChange(event, data) {
       this.setState({...this.state, text: event.target.value})
    }

    render() {
        let {id, style, namespace} = this.props;
        const textStore = this.state.textStore;

        let displayType = userStore.userFromServer !== null ? "admin" : "user";

        let contentToShow = "";

        if (displayType === "admin") {

            let textToEdit = {content: ""};

            if (typeof textStore[id] !== "undefined") {
                if (typeof textStore[id][this.language] !== "undefined") {
                    textToEdit = textStore[id][this.language];
                } else {
                    textToEdit = {}
                }
            }


            if (this.state.editState) {
                if (style === null) {
                    style = {
                        "zIndex": 1,
                        "position": "relative",
                        "backgroundColor": "white",
                        "overflow": "auto"
                    };
                } else {
                    // need to copy this because otherwise I get "not extendable error"
                    let customStyle = Object.assign({}, style);
                    customStyle.zIndex = 1;
                    customStyle.position = "relative";
                    customStyle.backgroundColor = "white";
                    customStyle.overflow = "auto";
                    style = customStyle;
                }


                contentToShow = <div style={style}>
                    <Form>
                        <Form.TextArea
                            value={typeof this.state.text === "undefined" ? "" : this.state.text}
                            onChange={this.handleChange.bind(this)}/>
                    </Form>
                    <br/>
                    <Button.Group>
                        <Button color="green" onClick={() => {
                            textToEdit = {
                                content: this.state.text,
                                title: "",
                                description: ""
                            };

                            if (typeof textStore[id] === "undefined" || typeof textStore[id][this.language] === "undefined") {
                                createText(id, textToEdit, this.language, namespace).then(isSuccess => {
                                    if (isSuccess) {
                                        fetchTextById(id).then(text => {
                                            textStore[id] = text[id]
                                            this.setState({...this.state, editState: false})
                                        })
                                    }
                                }).catch((error) => {
                                    console.log(error)
                                });
                            } else {
                                updateText(id, textToEdit, this.language, namespace).then(isSuccess => {
                                    if (isSuccess) {
                                        fetchTextById(id).then(text => {
                                            textStore[id] = text[id]
                                            this.setState({...this.state, editState: false})
                                        })
                                    }
                                }).catch((error) => {
                                    console.log(error)
                                });
                            }
                        }}>
                            Speichern
                        </Button>
                        <Button color="red" onClick={() => {
                            this.setState({...this.state, editState: false})
                        }}>Abbrechen</Button>
                    </Button.Group>
                </div>
            } else {
                contentToShow = <div style={style}>
                    {textToEdit.content}
                    {textToEdit.isNamespace === this.props.namespace ? "" :
                        <Label size={"tiny"} className={"namespace-note"} style={{opacity: "80%"}} floating
                               color={"red"}>!</Label>}
                    <br/>
                    <Button color="orange" icon="edit outline" onClick={() => {
                        this.setState({...this.state, editState: true, text: textToEdit.content})
                    }}/>
                </div>
            }
        } else {
            let textToShow = {content: ""};

            if (typeof textStore[id] !== "undefined") {
                if (typeof textStore[id][this.language] !== "undefined") {
                    textToShow = textStore[id][this.language];
                } else {
                    textToShow = {};
                }
            }
            contentToShow = textToShow.content;
        }
        return (
            contentToShow
        );
    }
}

EditSimpleValueComponent.propTypes = {
    textStore: PropTypes.object,
    namespace: PropTypes.string,
    id: PropTypes.string,
    style: PropTypes.object
};

EditSimpleValueComponent.defaultProps = {
    textStore: {},
    namespace: null,
    id: Math.random().toString(),
    style: null
};

export default EditSimpleValueComponent