import React from "react";
import {inject} from "mobx-react";
import EditSimpleValueComponent from "../../components/moap-components/EditSimpleValueComponent";
//import EditHTMLComponent from "../../components/moap-components/EditHTMLComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import {Accordion} from "semantic-ui-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import EditHTMLComponent from "../../components/moap-components/EditHTMLComponent";


const namespace = "donate"

@inject("stores")
class DonatePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            textStore: props.stores.textStore.texts,
            isLoading: true,
            location: this.props.location || "",
            showDonationForm: this.props.location.endsWith("donate-anchor"),
            showPresentForm: false,
            showPartnerForm: false,
        }

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let self = this;
        if (typeof window !== "undefined") {
            const iframeEle = document.getElementById('iframe-donationform-default');
            iframeEle.addEventListener('load', () => {
                // Hide the loading indicator
                self.setState({...self.state, isLoading: false})

                // Bring the iframe back
                iframeEle.style.opacity = 1;
            });
        }
    }

    render() {
        return (
            <Grid>
                {/*<TitleImageComponent
                    textStore={this.state.textStore}
                    namespace={'donate'}
                    imageLinkId={"donate-title-image-id"}
                    headerId={"donate-title-header-id"}
                    subheaderId={"donate-subtitle-header-id"}/>*/}
                <Grid.Row centered>
                    <Grid.Column computer={6} tablet={8} mobile={16}>
                        <h2 style={{marginTop: "20px", marginBottom: "0px"}}>

                            <EditSimpleValueComponent textStore={this.state.textStore} id={"donate-header-1-id"}
                                                      namespace={'donate'}/>
                        </h2>
                        <p className={"text-block"}>

                            <EditSimpleValueComponent textStore={this.state.textStore} id={`${namespace}-text-1-id`}
                                                      namespace={namespace}/>
                        </p>
                        <iframe
                            style={{
                                "width": "200px",
                                "height": "150px",
                                "display": "block",
                                "margin": "0 auto"
                            }}
                            src="https://www.bildungsspender.org/extern/wch/spendenstandbanner.php?kt=488212003&url=bskr"
                            frameBorder="0"/>
                    </Grid.Column>
                    <Grid.Column computer={6} tablet={8} mobile={16}>
                        <h2 style={{marginTop: "20px", marginBottom: "0px"}}>

                            <EditSimpleValueComponent textStore={this.state.textStore} id={`${namespace}-header-3-id`}
                                                      namespace={namespace}/>
                        </h2>
                        <p className={"text-block"}>

                            <EditSimpleValueComponent textStore={this.state.textStore} id={`${namespace}-text-3-id`}
                                                      namespace={namespace}/>
                        </p>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column width={12}>
                        <Accordion styled style={{width: '100%'}} id={'donate-anchor'}>
                            <Accordion.Title active={this.state.showDonationForm}
                                             style={{display: 'inline-flex'}}
                                             onClick={_ => {
                                                 this.setState({
                                                     ...this.state,
                                                     showDonationForm: !this.state.showDonationForm
                                                 })
                                             }}>
                                <Icon name={this.state.showDonationForm ? 'chevron down' : 'chevron right'}
                                      style={{margin: 'auto 0'}}
                                      size={"large"}/>
                                <h2 style={{margin: 'auto 0', overflowWrap: 'anywhere'}}>Spendenformular</h2>

                            </Accordion.Title>
                            <Accordion.Content active={this.state.showDonationForm}>
                                <Grid.Column width={15}>

                                    <Loader active={this.state.isLoading} inline={'centered'}
                                            id={'loading-donation'}>Lädt...</Loader>
                                    <iframe
                                        src="https://www.helpmundo.de/helptools/donationsv2/15623/0/?df_paragraph_text_color=&amp;df_secondary_text_color=&amp;df_active_bg_color=004e84&amp;df_active_text_color=ffffff&amp;df_bg_color=&amp;df_font_type=lato&amp;_=1576655726762"
                                        frameBorder="0" scrolling="no" id="iframe-donationform-default"
                                        style={{"width": "100%", "height": "1050px", "overflow": "hidden"}}/>
                                </Grid.Column>
                            </Accordion.Content>
                        </Accordion>
                        <Accordion styled style={{width: '100%', marginTop: '2rem'}}>
                            <Accordion.Title active={this.state.showPresentForm}
                                             style={{display: 'inline-flex'}}
                                             onClick={_ => {
                                                 this.setState({
                                                     ...this.state,
                                                     showPresentForm: !this.state.showPresentForm
                                                 })
                                             }}>
                                <Icon name={this.state.showPresentForm ? 'chevron down' : 'chevron right'}
                                      style={{margin: 'auto 0'}}
                                      size={"large"}/>
                                <h2 style={{margin: 'auto 0', overflowWrap: 'anywhere'}}>Spenden statt Geschenke</h2>

                            </Accordion.Title>
                            <Accordion.Content active={this.state.showPresentForm}>
                                <Grid.Column width={15}>
                                    <EditHTMLComponent namespace={namespace} textStore={this.props.textStore}
                                                       id={`${namespace}-accordion-text-1`}/>
                                </Grid.Column>
                            </Accordion.Content>
                        </Accordion>
                        <Accordion styled style={{width: '100%', marginTop: '2rem'}}>
                            <Accordion.Title active={this.state.showPartnerForm}
                                             style={{display: 'inline-flex'}}
                                             onClick={_ => {
                                                 this.setState({
                                                     ...this.state,
                                                     showPartnerForm: !this.state.showPartnerForm
                                                 })
                                             }}>
                                <Icon name={this.state.showPartnerForm ? 'chevron down' : 'chevron right'}
                                      style={{margin: 'auto 0'}}
                                      size={"large"}/>
                                <h2 style={{margin: 'auto 0', overflowWrap: 'anywhere'}}>Patenschaft</h2>

                            </Accordion.Title>
                            <Accordion.Content active={this.state.showPartnerForm}>
                                <Grid.Column width={15}>
                                    <EditHTMLComponent namespace={namespace} textStore={this.props.textStore}
                                                       id={`${namespace}-accordion-text-2`}/>
                                </Grid.Column>
                            </Accordion.Content>
                        </Accordion>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default DonatePage
/*
   <p className={"text-block"}>
                            <EditTextComponent id={"donate-sub-description-id"}/>
                        </p>
 */