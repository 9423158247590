import React from "react"
import NavigationBar from "./navigation/NavigationBar";
import {renderRoutes} from "react-router-config";
import allRoutes from "../../shared/routes";
import FooterComponent from "./footer/FooterComponent";

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import ScrollUpButton from "./scroll_up_button/ScrollUpButton";
import StickyThingy from "./sticky-thingy/StickyThingy";

class App extends React.Component {

    render() {
        return (
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <NavigationBar/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered columns={1}>
                    <Grid.Column style={{"padding": "32px", paddingTop: 0, "minHeight": "70vh"}}>
                        {renderRoutes(allRoutes)}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{"paddingBottom": "0px"}}>
                    <Grid.Column width={16}>
                        <FooterComponent/>
                    </Grid.Column>
                </Grid.Row>
                <StickyThingy/>
                <ScrollUpButton/>
            </Grid>
        );
    }
}

export default App