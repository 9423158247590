import React from "react";
import userStore from "../../stores/userStore";
import PropTypes from "prop-types";
import {observable} from "mobx";
import {observer} from "mobx-react";
import browserHistory from "../../helper/browserHistory";
import {fetchTextById} from "../../../server/helper/ssrHelper";
import {createText, updateText} from "../../helper/util";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";

//import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";

@observer
class EditTextButtonComponent extends React.Component {
    @observable language = "de-DE";

    constructor(props) {
        super(props);
        this.state = {
            textStore: this.props.textStore,
            editState: false,
            editLinkState: false,
            editValue: "",
            editLinkValue: ""
        }
    }

    componentDidMount() {
        let ids = [this.props.linkTextId, this.props.textId];
        for (let i = 0; i < ids.length; i++) {
            if (typeof this.props.textStore[ids[i]] === "undefined") {
                fetchTextById(ids[i]).then(text => {
                    if (typeof text !== "undefined") {
                        this.setState(prevState => ({
                            ...prevState,
                            textStore: {...prevState.textStore, [ids[i]]: text[ids[i]]}
                        }));
                    }
                })
            }
        }
    }

    handleChange(type, event) {
        if (type === 0) {
            this.setState({...this.state, editValue: event.target.value});
        } else {
            this.setState({...this.state, editLinkValue: event.target.value});
        }
        this.setState({})
    }


    render() {
        const {textId, linkTextId, cssClass} = this.props;
        const textStore = this.state.textStore
        if (!textId || !linkTextId) {
            console.log('Src is not acceptable');
            return null;
        }

        let {id} = this.props;
        // TODO: Make also to const this is only for test purpose set to let
        let displayType = userStore.userFromServer !== null ? "admin" : "user";

        let contentToShow = null;

        let textToEdit = {content: ""};
        if (typeof textStore[textId] !== "undefined") {
            if (typeof textStore[textId][this.language] !== "undefined") {
                textToEdit = textStore[textId][this.language];
            } else {
                textToEdit = {};
            }
        }

        let linkToEdit = {content: ""};
        if (typeof textStore[linkTextId] !== "undefined") {
            if (typeof textStore[linkTextId][this.language] !== "undefined") {
                linkToEdit = textStore[linkTextId][this.language];
            } else {
                linkToEdit = {};
            }
        }

        if (displayType === "admin") {

            let style = {
                "zIndex": 1,
                "position": "relative",
                "overflow": "auto"
            };
            contentToShow = <div style={style}>

                {this.state.editState ?
                    <div>
                        <Form>
                            <p><b>Button Text</b></p>
                            <Form.Input
                                value={typeof this.state.editValue === "undefined" ? "" : this.state.editValue}
                                onChange={e => this.handleChange(0, e)}/>
                        </Form>
                        <br/>
                        <Button.Group>
                            <Button color="green" onClick={() => {
                                textToEdit = {
                                    content: this.state.editValue,
                                    title: "",
                                    description: ""
                                };


                                if (typeof textStore[textId] === "undefined" || typeof textStore[textId][this.language] === "undefined") {
                                    createText(textId, textToEdit, this.language, this.props.namespace).then(isSuccess => {
                                        if (isSuccess) {
                                            fetchTextById(textId).then(text => {
                                                textStore[textId] = text[textId];
                                                this.setState({...this.state, editState: false});
                                            })
                                        }
                                    }).catch((error) => {
                                        console.log(error)
                                    });
                                } else {
                                    updateText(textId, textToEdit, this.language, this.props.namespace).then(isSuccess => {
                                        if (isSuccess) {
                                            fetchTextById(textId).then(text => {
                                                textStore[textId] = text[textId];
                                                this.setState({...this.state, editState: false});
                                            })
                                        }
                                    }).catch((error) => {
                                        console.log(error)
                                    });
                                }
                            }}>
                                Speichern
                            </Button>
                            <Button color="red" onClick={() => {
                                this.setState({...this.state, editState: false});
                            }}>Abbrechen</Button>
                        </Button.Group>
                    </div>
                    :
                    <div>
                        <p><b>Button Text</b></p>
                        <p>{textToEdit.content}</p>
                        <br/>
                        <Button color="orange" icon="edit outline" onClick={() => {
                            this.setState({...this.state, editState: true, editValue: textToEdit.content});
                        }}/>
                    </div>

                }
                {this.state.editLinkState ?
                    <div>
                        <Form>
                            <p><b>Button Link</b></p>
                            <Form.Input
                                value={typeof this.state.editLinkValue === "undefined" ? "" : this.state.editLinkValue}
                                onChange={e => this.handleChange(1, e)}/>
                        </Form>
                        <br/>
                        <Button.Group>
                            <Button color="green" onClick={() => {
                                textToEdit = {
                                    content: this.state.editLinkValue,
                                    title: "",
                                    description: ""
                                };

                                if (typeof textStore[linkTextId] === "undefined" || typeof textStore[linkTextId][this.language] === "undefined") {
                                    createText(linkTextId, textToEdit, this.language, this.props.namespace).then(isSuccess => {
                                        if (isSuccess) {
                                            fetchTextById(linkTextId).then(text => {
                                                textStore[linkTextId] = text[linkTextId];
                                                this.setState({...this.state, editLinkState: false})
                                            })
                                        }
                                    }).catch((error) => {
                                        console.log(error)
                                    });
                                } else {
                                    updateText(linkTextId, textToEdit, this.language, this.props.namespace).then(isSuccess => {
                                        if (isSuccess) {
                                            fetchTextById(linkTextId).then(text => {
                                                textStore[linkTextId] = text[linkTextId];
                                                this.setState({...this.state, editLinkState: false})
                                            })
                                        }
                                    }).catch((error) => {
                                        console.log(error)
                                    });
                                }
                            }}>
                                Speichern
                            </Button>

                            <Button color="red" onClick={() => {
                                this.setState({...this.state, editLinkState: false})
                            }}>Abbrechen</Button>
                        </Button.Group>
                    </div>
                    :
                    <div>
                        <p><b>Button Link</b></p>
                        <p>
                            <div id={id} dangerouslySetInnerHTML={{__html: linkToEdit.content}}/>
                        </p>
                        {linkToEdit.isNamespace === this.props.namespace ? "" :
                            <Label size={"tiny"} className={"namespace-note"} style={{opacity: "80%"}} floating
                                   color={"red"}>!</Label>}
                        <br/>
                        <Button color="orange" icon="edit outline" onClick={() => {
                            this.setState({...this.state, editLinkState: true, editLinkValue: linkToEdit.content})
                        }}/>
                    </div>
                }
            </div>;
        } else {

            contentToShow = <Button className={cssClass}
                                    onClick={() => {
                                        if (linkToEdit.content.startsWith("http")) {
                                            window.open(linkToEdit.content, "_blank")
                                        } else {
                                            browserHistory.push(linkToEdit.content);
                                            this.setState({});
                                        }
                                    }}>
                {textToEdit.content}
            </Button>;
        }
        return (
            contentToShow
        )
    }
}

EditTextButtonComponent.propTypes = {
    textStore: PropTypes.object,
    namespace: PropTypes.string,
    textId: PropTypes.string,
    linkTextId: PropTypes.string,
    cssClass: PropTypes.string,
};

EditTextButtonComponent.defaultProps = {
    textStore: {},
    namespace: null,
    textId: null,
    linkTextId: null,
    cssClass: ""
};

export default EditTextButtonComponent