/**
 * Created by Matijas on 11.04.2017.
 */

import { isDevelop } from "../helper/util";

export default {
    BASE_URL: isDevelop() ? "http://localhost:3000/api/" : "https://buergerstiftung-kreis-rv.de/api/",
    PUBLIC_URL: isDevelop() ? "http://localhost:3000/" : "https://buergerstiftung-kreis-rv.de/",
    BASE_IMAGE_URL: isDevelop() ? "http://localhost:3000/images/" : "https://buergerstiftung-kreis-rv.de/images/",
    STORAGE_KEY: "4WfG5jv3",
    USER_STORAGE_KEY: "cjhSopcv0w9"
}