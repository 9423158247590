import React, {useState} from "react";
import NewsletterModal from "../modal/NewsletterModal";
import history from "../../helper/browserHistory";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";


const StickyThingy = () => {
    const [newsletterModalOpen, setNewsletterModalOpen] = useState(false)

    return (
        <div>
            <div className={"grid sticky-thingy no-padding"}>
                <button className={"no-border"} style={{backgroundColor: "#ea6a41"}}
                        onClick={() => setNewsletterModalOpen(!newsletterModalOpen)}>
                    <Icon className={"sticky-thingy-icon"} name="newspaper outline big"/>
                    <p className={"sticky-thingy-text"}>NEWSLETTER</p>
                </button>
                <button className={"no-border"} style={{backgroundColor: "#94bb6a"}} onClick={() => {
                    history.push('/contact')
                }}>
                    <Icon className={"sticky-thingy-icon"} name="envelope outline big"/>
                    <p className={"sticky-thingy-text"}>KONTAKT</p>
                </button>
            </div>
            <NewsletterModal open={newsletterModalOpen} onClose={()=>setNewsletterModalOpen(false)}/>
        </div>
    )
}

export default StickyThingy;