import React from "react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {Fade} from "react-reveal";

class ScrollButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_visible: false
        };
    }

    componentDidMount() {
        var scrollComponent = this;
        document.addEventListener("scroll", function (e) {
            scrollComponent.toggleVisibility();
        });
    }

    toggleVisibility() {
        if (window.pageYOffset > 500) {
            this.setState({
                is_visible: true
            });
        } else {
            this.setState({
                is_visible: false
            });
        }
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    render() {
        return (
            <div className="scroll-to-top">
                <Fade top when={this.state.is_visible}>
                    <div onClick={() => this.scrollToTop()}>
                        <Icon
                            name={"angle up"}
                            circular
                            size={"big"}
                            className={"scroll-up-button-icon"}/>
                    </div>
                </Fade>
            </div>
        );
    }
}

export default ScrollButton
