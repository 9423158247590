import React from "react";
import PropTypes from "prop-types";
import userStore from "../../stores/userStore"
import {Helmet} from "react-helmet";
import {observer} from "mobx-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import {fetchTextById} from "../../../server/helper/ssrHelper";
import {createText, updateText} from "../../helper/util";
import {Label} from "semantic-ui-react";

@observer
class EditHeaderComponent extends React.Component {


    constructor(props) {
        super(props);

        this.changeText = this.changeText.bind(this);

        this.state = {
            htmlText: '',
            title: '',
            description: '',
            language: 'de-DE',
            textStore: this.props.textStore,
            editState: false
        }

    }
    componentDidMount() {
        if (typeof this.props.textStore[this.props.id] === "undefined") {
            fetchTextById(this.props.id).then(text => {
                if (typeof text !== "undefined") {
                    this.setState({...this.state, textStore: {[this.props.id]: text[this.props.id]}})
                }
            })
        }
    }


    handleChange = (e, {value}) => this.setState({
        ...this.state,
        language: value,
        htmlText: '',
        description: '',
        title: ''
    });

    changeText(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            ...this.state,
            [name]: value
        });
    }

    render() {

        let {id, style} = this.props;
        const textStore = this.state.textStore;
        let language = "de-DE";
        const displayType = userStore.userFromServer !== null ? "admin" : "user";
        let contentToShow;

        if (displayType === "admin") {
            let textToEdit = {};

            if (typeof textStore[id] !== "undefined") {
                if (typeof textStore[id][this.state.language] !== "undefined") {
                    textToEdit = textStore[id][this.state.language];
                } else {
                    textToEdit = {};
                }
            }

            if (this.state.htmlText !== '') {
                textToEdit.content = this.state.htmlText;
            }

            if (this.state.title !== '') {
                textToEdit.title = this.state.title;
            }

            if (this.state.description !== '') {
                textToEdit.description = this.state.description;
            }

            if (this.state.editState) {
                if (style === null) {
                    style = {
                        "zIndex": 1,
                        "position": "relative",
                        "backgroundColor": "white",
                        "overflow": "auto"
                    };
                } else {
                    // need to copy this because otherwise I get "not extendable error"
                    let customStyle = Object.assign({}, style);
                    customStyle.zIndex = 1;
                    customStyle.position = "relative";
                    customStyle.backgroundColor = "white";
                    customStyle.overflow = "auto";
                    style = customStyle;
                }

                contentToShow = <div style={style}>
                    <Form>
                        <Form.Field>
                            <label>Title Tag</label>
                            <Input value={typeof textToEdit.title === "undefined" ? "" : textToEdit.title}
                                   name='title'
                                   onChange={this.changeText}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Description Tag</label>
                            <Input value={typeof textToEdit.description === "undefined" ? "" : textToEdit.description}
                                   name='description'
                                   onChange={this.changeText}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Text</label>
                            <TextArea style={{height: "150px", width: "100%"}}
                                      name='htmlText'
                                      onChange={this.changeText}
                                      value={typeof textToEdit.content === "undefined" ? "" : textToEdit.content}/>
                        </Form.Field>
                        <Form.Field>
                            <Button.Group>
                                <Button color="green" onClick={() => {

                                    if (this.state.htmlText !== '') {
                                        textToEdit.content = this.state.htmlText;
                                    }

                                    if (this.state.title !== '') {
                                        textToEdit.title = this.state.title;
                                    }

                                    if (this.state.description !== '') {
                                        textToEdit.description = this.state.description;
                                    }

                                    if (typeof textStore[id] === "undefined" || typeof textStore[id][this.state.language] === "undefined") {
                                        createText(id, textToEdit, this.state.language, this.props.namespace).then(isSuccess => {
                                            if (isSuccess) {
                                                fetchTextById(id).then(text => {
                                                    textStore[id] = text[id]
                                                    this.setState({...this.state, editState: false})
                                                })
                                            }
                                        }).catch((error) => {
                                            console.log(error)
                                        });
                                    } else {
                                        updateText(id, textToEdit, this.state.language, this.props.namespace).then(isSuccess => {
                                            if (isSuccess) {
                                                fetchTextById(id).then(text => {
                                                    textStore[id] = text[id]
                                                    this.setState({...this.state, editState: false})
                                                })
                                            }
                                        }).catch((error) => {
                                            console.log(error)
                                        });
                                    }
                                }}>
                                    {typeof textStore[id] === "undefined" || typeof textStore[id][this.state.language] === "undefined" ? "Speichern" : "Bearbeiten"}
                                </Button>
                                <Button color="red" onClick={() =>   this.setState({...this.state, editState: false})}>Abbrechen</Button>
                            </Button.Group>
                        </Form.Field>
                    </Form>

                </div>
            } else {
                contentToShow = <div style={style}>
                    <h1 id={id}>{textToEdit.content}</h1>
                    <p id={"ssr" + id}>Page-title: {textToEdit.title} | Page-tags: {textToEdit.description}</p>
                    {textToEdit.isNamespace === this.props.namespace ? "" :
                        <Label size={"tiny"} className={"namespace-note"} style={{opacity: "80%"}} floating color={"red"}>!</Label>}
                    <br/>
                    <Button color="orange" icon="edit outline" onClick={() => {
                        this.setState({...this.state, editState: true})
                    }}/>
                </div>
            }

        } else {
            let textToShow = {};

            if (typeof textStore[id] !== "undefined") {
                if (typeof textStore[id][language] !== "undefined") {
                    textToShow = textStore[id][language];
                } else {
                    textToShow = {};
                }
            }

            contentToShow = <div>
                <Helmet>
                    <title>{textToShow.title ? textToShow.title : textToShow.content}</title>
                    <meta name="title"
                          content={textToShow.title ? textToShow.title : textToShow.content}/>
                    <meta name="description"
                          content={textToShow.description ? textToShow.description : textToShow.content}/>
                    <meta name="robots" content="index,follow"/>
                </Helmet>
                <h1 style={style}>{textToShow.content}</h1>
            </div>;
        }


        return (
            contentToShow
        );
    }
}

EditHeaderComponent.propTypes = {
    textStore: PropTypes.object,
    namespace: PropTypes.string,
    id: PropTypes.string,
    style: PropTypes.object
};

EditHeaderComponent.defaultProps = {
    textStore: {},
    namespace: null,
    id: Math.random().toString(),
    style: null
};

export default EditHeaderComponent