import { loadArticle, loadCurrent, loadTextsByNamespace } from "../server/helper/ssrHelper";
import Loadable from 'react-loadable';
import LoaderComponent from "../src/components/LoaderComponent";
import FundraisingPage from "../src/pages/fundraising/FundraisingPage";


const Login = Loadable({
    loader: () => import(/* webpackChunkName: 'Login' */ "../src/pages/login/Login"),
    loading: () => LoaderComponent
});

const PartnerPage = Loadable({
    loader: () => import(/* webpackChunkName: 'PartnerPage' */ "../src/pages/partner/PartnerPage"),
    loading: () => LoaderComponent
});

const BlogAuthorOverviewPage = Loadable({
    loader: () => import(/* webpackChunkName: 'BlogAuthorOverviewPage' */ "../src/pages/blog/BlogAuthorOverview"),
    loading: () => LoaderComponent
});

const BlogAuthorEditPage = Loadable({
    loader: () => import(/* webpackChunkName: 'BlogAuthorEditPage' */ "../src/pages/blog/BlogAuthorEdit"),
    loading: () => LoaderComponent
});

const UserManagementPage = Loadable({
    loader: () => import(/* webpackChunkName: 'UserManagementPage' */ "../src/pages/blog/BlogManagement"),
    loading: () => LoaderComponent
});

const StifterBriefPage = Loadable({
    loader: () => import(/* webpackChunkName: 'StifterBriefPage' */ "../src/pages/stifter/StifterBriefPage"),
    loading: () => LoaderComponent
});

const HomePage = Loadable({
    loader: () => import(/* webpackChunkName: 'HomePage' */ "../src/pages/home/HomePage"),
    loading: () => LoaderComponent
});

const NotFoundPage = Loadable({
    loader: () => import(/* webpackChunkName: 'NotFoundPage' */ "../src/pages/not_found/NotFoundPage"),
    loading: () => LoaderComponent
});

const CurrentPage = Loadable({
    loader: () => import(/* webpackChunkName: 'CurrentPage' */ "../src/pages/current/CurrentPage"),
    loading: () => LoaderComponent
});

const EngagePage = Loadable({
    loader: () => import(/* webpackChunkName: 'EngagePage' */ "../src/pages/engage/EngagePage"),
    loading: () => LoaderComponent
});

const PromotionsPage = Loadable({
    loader: () => import(/* webpackChunkName: 'PromotionsPage' */ "../src/pages/promotion/PromotionsPage"),
    loading: () => LoaderComponent
});

const AboutPage = Loadable({
    loader: () => import(/* webpackChunkName: 'AboutPage' */ "../src/pages/about/AboutPage"),
    loading: () => LoaderComponent
});

const Transparenz = Loadable({
    loader: () => import(/* webpackChunkName: 'Transparenz' */ "../src/components/tranparency/Transparenz"),
    loading: () => LoaderComponent
});

const DownloadPage = Loadable({
    loader: () => import(/* webpackChunkName: 'DownloadPage' */ "../src/pages/download/DownloadPage"),
    loading: () => LoaderComponent
});

const StatuePage = Loadable({
    loader: () => import(/* webpackChunkName: 'StatuePage' */ "../src/pages/statute/StatuePage"),
    loading: () => LoaderComponent
});

const DataProtectionPage = Loadable({
    loader: () => import(/* webpackChunkName: 'DataProtectionPage' */ "../src/pages/data_privacy/DataProtectionPage"),
    loading: () => LoaderComponent
});

const ContactPage = Loadable({
    loader: () => import(/* webpackChunkName: 'ContactPage' */ "../src/pages/contact/ContactPage"),
    loading: () => LoaderComponent
});

const Imprint = Loadable({
    loader: () => import(/* webpackChunkName: 'Imprint' */ "../src/pages/imprint/Imprint"),
    loading: () => LoaderComponent
});

const AmOrtStiftungPage = Loadable({
    loader: () => import(/* webpackChunkName: 'AmOrtStiftungPage' */ "../src/pages/partner/AmOrtStiftung"),
    loading: () => LoaderComponent
});

const BuergerStiftungRavensburg = Loadable({
    loader: () => import(/* webpackChunkName: 'BuergerStiftungRavensburg' */ "../src/pages/partner/BuergerStiftungRavensburg"),
    loading: () => LoaderComponent
});

const BuergerStiftungBaindt = Loadable({
    loader: () => import(/* webpackChunkName: 'BuergerStiftungBaindt' */ "../src/pages/partner/BuergerstiftungBaindt"),
    loading: () => LoaderComponent
});

const LegnerSautter = Loadable({
    loader: () => import(/* webpackChunkName: 'LegnerSautter' */ "../src/pages/partner/Legner-Sautter"),
    loading: () => LoaderComponent
});

const HospizStiftung = Loadable({
    loader: () => import(/* webpackChunkName: 'HospizStiftung' */ "../src/pages/partner/HospizStiftung"),
    loading: () => LoaderComponent
});

const BlogArticlePage = Loadable({
    loader: () => import(/* webpackChunkName: 'BlogArticlePage' */ "../src/pages/blog/BlogArticlePage"),
    loading: () => LoaderComponent
});

const SearchPage = Loadable({
    loader: () => import(/* webpackChunkName: 'SearchPage' */ "../src/pages/search/SearchPage"),
    loading: () => LoaderComponent
});

const InOwnNamePage = Loadable({
    loader: () => import(/* webpackChunkName: 'InOwnNamePage' */ "../src/pages/in_own_name/InOwnNamePage"),
    loading: () => LoaderComponent
});

const EndownmentPage = Loadable({
    loader: () => import(/* webpackChunkName: 'EndownmentPage' */ "../src/pages/endownment/EndownmentPage"),
    loading: () => LoaderComponent
});

const DrepsPage = Loadable({
    loader: () => import(/* webpackChunkName: 'DrepsPage' */ "../src/pages/dreps/DrepsPage"),
    loading: () => LoaderComponent
});

const ValentinaPage = Loadable({
    loader: () => import(/* webpackChunkName: 'ValentinaPage' */ "../src/pages/valentina/ValentinaPage"),
    loading: () => LoaderComponent
});

const InheritPage = Loadable({
    loader: () => import(/* webpackChunkName: 'InheritPage' */ "../src/pages/inherit/InheritPage"),
    loading: () => LoaderComponent
});

const IdeasPage = Loadable({
    loader: () => import(/* webpackChunkName: 'IdeasPage' */ "../src/pages/ideas/IdeasPage"),
    loading: () => LoaderComponent
});

const PressPage = Loadable({
    loader: () => import(/* webpackChunkName: 'PressPage' */ "../src/pages/press/PressPage"),
    loading: () => LoaderComponent
});

const CompanyEngagementPage = Loadable({
    loader: () => import(/* webpackChunkName: 'CompanyEngagementPage' */ "../src/pages/engage/CompanyEngagementPage"),
    loading: () => LoaderComponent
});

const FundraisingOverviewPage = Loadable({
    loader: () => import(/* webpackChunkName: 'FundraisingOverviewPage' */ "../src/pages/fundraising/FundraisingOverviewPage"),
    loading: () => LoaderComponent
});

const FundraisingProjectPage = Loadable({
    loader: () => import(/* webpackChunkName: 'FundraisingProjectPage' */ "../src/pages/fundraising/FundraisingProjectPage"),
    loading: () => LoaderComponent
});

const ProjectPage = Loadable({
    loader: () => import(/* webpackChunkName: 'ProjectPage' */ "../src/pages/project/ProjectPage"),
    loading: () => LoaderComponent
});

const NewsletterPage = Loadable({
    loader: () => import(/* webpackChunkName: 'NewsletterPage' */ "../src/pages/SuccessNewsletterPage"),
    loading: () => LoaderComponent
});

const sharedRoutes = [
    {
        path: "/",
        exact: true,
        component: HomePage,
        namespace: "home",
        loadData: (data, baseUrl) => {
            return loadTextsByNamespace(baseUrl, "home");
        }
    }, {
        path: "/404",
        exact: true,
        component: NotFoundPage
    }, {
        path: "/dankeseite",
        exact: true,
        component: NewsletterPage
    }, {
        path: "/current",
        exact: true,
        component: CurrentPage,
        namespace: "current",
        loadData: async (data, baseUrl) => {
            //let texts = await loadContentWithFrontendIds(baseUrl, ["current-title-image-id", "current-title-header-id", "current-subtitle-header-id"]);
            let texts = await loadTextsByNamespace(baseUrl, "current");
            if (!texts.error) {
                let dataForPage = await loadCurrent(baseUrl);

                return {
                    dataForPage: dataForPage.dataForPage,
                    texts: texts.texts
                }
            } else {
                return loadCurrent(baseUrl);
            }
        }

    }, {
        path: "/engage",
        exact: true,
        component: EngagePage,
        namespace: "engage",
        loadData: (data, baseUrl) => {
            //  return loadContentWithFrontendIds(baseUrl, ["engage-title-image-id", "engage-title-header-id", "engage-subtitle-header-id"]);
            return loadTextsByNamespace(baseUrl, "engage");
        }
    }, {
        path: "/partner",
        exact: true,
        component: PartnerPage,
        namespace: "partner",
        loadData: (data, baseUrl) => {
            //  return loadContentWithFrontendIds(baseUrl, ["engage-title-image-id", "engage-title-header-id", "engage-subtitle-header-id"]);
            return loadTextsByNamespace(baseUrl, "partner");
        }
    },
    {
        path: "/unsere_schwerpunkte",
        exact: true,
        component: ProjectPage,
        namespace: "unsere_schwerpunkte",
        loadData: (data, baseUrl) => {
            return loadTextsByNamespace(baseUrl, "unsere_schwerpunkte");
        }
    },
    {
        path: "/promotions",
        exact: true,
        component: PromotionsPage,
        namespace: "promotions",
        loadData: (data, baseUrl) => {
            // return loadContentWithFrontendIds(baseUrl, ["promotion-title-image-id", "promotion-title-header-id", "promotion-subtitle-header-id"]);
            return loadTextsByNamespace(baseUrl, "promotions");
        }
    }, {
        path: "/about",
        exact: true,
        component: AboutPage,
        namespace: "about",
        loadData: (data, baseUrl) => {
            // return loadContentWithFrontendIds(baseUrl, ["about-title-image-id", "about-title-header-id", "about-subtitle-header-id"]);
            return loadTextsByNamespace(baseUrl, 'about');
        }
    }, {
        path: "/transparenz",
        exact: true,
        component: Transparenz,
        namespace: "transparenz",
        loadData: (data, baseUrl) => {
            //  return loadContentWithFrontendIds(baseUrl, ["transparenz-title-image-id", "transparenz-title-header-id", "transparenz-subtitle-header-id"]);
            return loadTextsByNamespace(baseUrl, "transparenz");
        }
    },
    {
        path: "/spendensammlung",
        exact: true,
        component: FundraisingPage,
        namespace: "donate",
        loadData: (data, baseUrl) => {
            return loadTextsByNamespace(baseUrl, "donate");
        }
    }, {
        path: "/admin-login",
        exact: true,
        component: Login
    }, {
        path: "/download",
        exact: true,
        component: DownloadPage
    }, {
        path: "/statute",
        exact: true,
        component: StatuePage
    }, {
        path: "/dataProtection",
        exact: true,
        component: DataProtectionPage
    }, {
        path: "/contact",
        exact: true,
        loadData: (data, baseUrl) => {
            return loadTextsByNamespace(baseUrl, 'contact');
        },
        component: ContactPage
    }, {
        path: "/imprint",
        exact: true,
        component: Imprint
    }, {
        path: "/donate",
        exact: true,
        component: FundraisingPage,
        loadData: (data, baseUrl) => {
            return loadTextsByNamespace(baseUrl, "donate");
        }
    }, {
        path: "/blog-article/:id",
        exact: true,
        component: BlogArticlePage,
        loadData: (data, baseUrl) => {
            return loadArticle(data, baseUrl);
        }
    },
    {
        path: "/wissensfabrik",
        exact: true,
        component: BlogArticlePage,
    },
    {
        path: "/ball",
        exact: true,
        component: BlogArticlePage,
    },
    {
        path: "/preisgekroent24",
        exact: true,
        component: BlogArticlePage,
    },
    {
        path: "/search",
        exact: true,
        component: SearchPage
    }, {
        path: "/own-name",
        exact: true,
        namespace: "ownName",
        component: InOwnNamePage,
        loadData: (data, baseUrl) => {
            return loadTextsByNamespace(baseUrl, 'ownName');
        }
    }, {
        path: "/solidaritaet-am-ort",
        exact: true,
        namespace: "AmOrtStiftung",
        component: AmOrtStiftungPage,
        loadData: (data, baseUrl) => {
            return loadTextsByNamespace(baseUrl, 'AmOrtStiftung');
        }
    }, {
        path: "/ravensburg",
        exact: true,
        namespace: "BuergerStiftung",
        component: BuergerStiftungRavensburg,
        loadData: (data, baseUrl) => {
            return loadTextsByNamespace(baseUrl, 'BuergerStiftung');
        }
    },
    {
        path: "/legner-sautter",
        exact: true,
        namespace: "LegnerSautter",
        component: LegnerSautter,
        loadData: (data, baseUrl) => {
            return loadTextsByNamespace(baseUrl, 'LegnerSautter');
        }
    },
    {
        path: "/baindt",
        exact: true,
        namespace: "BuergerStiftungBaindt",
        component: BuergerStiftungBaindt,
        loadData: (data, baseUrl) => {
            return loadTextsByNamespace(baseUrl, 'BuergerStiftungBaindt');
        }
    },
    {
        path: "/hospizstiftung",
        exact: true,
        namespace: "HospizStiftung",
        component: HospizStiftung,
        loadData: (data, baseUrl) => {
            return loadTextsByNamespace(baseUrl, 'HospizStiftung');
        }
    }, {
        path: "/endowment",
        exact: true,
        namespace: "endowment",
        component: EndownmentPage,
        loadData: (data, baseUrl) => {
            return loadTextsByNamespace(baseUrl, 'endowment');
        }
    }, {
        path: "/dreps",
        exact: true,
        component: DrepsPage
    }, {
        path: "/stiftung-valentina",
        exact: true,
        component: ValentinaPage
    }, {
        path: "/tranparency",
        exact: true,
        component: InheritPage
    }, {
        path: "/ideas",
        exact: true,
        component: IdeasPage
    }, {
        path: "/blog-author-overview",
        exact: true,
        component: BlogAuthorOverviewPage
    }, {
        path: "/blog-author-edit/:id",
        exact: true,
        component: BlogAuthorEditPage
    }, {
        path: "/blog-management",
        exact: true,
        component: UserManagementPage
    }, {
        path: "/press",
        exact: true,
        component: PressPage
    }, {
        path: "/stifterbrief",
        exact: true,
        component: StifterBriefPage
    }, {
        path: "/enagement_fuer_unternehmen",
        exact: true,
        component: CompanyEngagementPage
    }, {
        path: "/kontakt",
        exact: true,
        component: ContactPage
    }, {
        path: "/projekte/bisherige-foerderungen",
        exact: true,
        component: CurrentPage
    }, {
        path: "/projekte",
        exact: true,
        component: CurrentPage
    }, {
        path: "/aktuelles",
        exact: true,
        component: CurrentPage
    }, {
        path: "/aktiv-dabei/zustiftung",
        exact: true,
        component: EngagePage
    }, {
        path: "/projekte/stiftung-valentina",
        exact: true,
        component: ValentinaPage
    }, {
        path: "/wir-ueber-uns.html",
        component: AboutPage
    }, {
        path: "/spendensammlung-admin",
        component: FundraisingOverviewPage
    }, {
        path: "/spendensammlung/:id",
        component: FundraisingProjectPage
    }
];

export default sharedRoutes;