import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import history from "../../helper/browserHistory";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import {useWindowSize} from "../../helper/util";
import {getAllActiveProjects} from "./FundraisingUtil";
import DonatePage from "../donate/DonatePage";
import {useLocation} from "react-router";
import {Message, Progress} from "semantic-ui-react";
import EditHTMLComponent from "../../components/moap-components/EditHTMLComponent";

const namespace = "donate";

function convertToShowDate(dateText) {
    let dateParts = dateText.split("-");
    let jsDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2].substr(0, 2));

    let month = "";
    switch (jsDate.getMonth()) {
        case 0:
            month = "JANUAR";
            break;
        case 1:
            month = "FEBRUAR";
            break;
        case 2:
            month = "MÄRZ";
            break;
        case 3:
            month = "APRIL";
            break;
        case 4:
            month = "MAI";
            break;
        case 5:
            month = "JUNI";
            break;
        case 6:
            month = "JULI";
            break;
        case 7:
            month = "AUGUST";
            break;
        case 8:
            month = "SEPTEMBER";
            break;
        case 9:
            month = "OKTOBER";
            break;
        case 10:
            month = "NOVEMBER";
            break;
        case 11:
            month = "DEZEMBER";
            break;
        default:
            break;

    }
    return jsDate.getDate() + ". " + month + " " + jsDate.getFullYear();
}

const FundraisingPage = inject("stores")(observer((props) => {
        const {textStore} = props;
        let applicationWidth = useWindowSize().width
        const {search} = useLocation();
        const [currentAmount, setCurrentAmount] = useState(2);
        const [Projects, setProjects] = useState([]);
        const [categories, setCategories] = useState(
            [{name: 'Alle Partner', active: true},
                {name: 'Stiftungen', active: false},
                {name: 'Unternehmen', active: false},
                {name: 'Vereine und Initiativen', active: false},
                {name: 'Gemeinden', active: false}]
        )
        useEffect(_ => {
            getAllActiveProjects().then(allProjects => {
                setProjects(allProjects);
            })
            window.scrollTo(0, 0);
            setTimeout(_ => {
                if (search && typeof window !== "undefined" && typeof document !== "undefined") {
                    let element = document.getElementById(search.substring(1, search.length));
                    if (element) {
                        element.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center'
                        })
                    }
                }
            }, 100);
            props.stores.trackingStore.addVisit("spendensammlung");
        }, [])

        const filter = (item) => {
            //no filter set
            if (categories[0].active) {
                return true
            }
            switch (item.type) {
                //Stiftungen
                case 0:
                    return categories[1].active;
                //Unternehmen
                case 1:
                    return categories[2].active;
                //Vereine und Initiativen
                case 2:
                    return categories[3].active;
                //Gemeinden
                case 3:
                    return categories[4].active;
                default:
                    return true;
            }
        }

        const showProjects = () => {
            const projects = Projects.map((item, index) => {
                if (index <= currentAmount) {
                    return (<Card>
                        <img src={item.thumbnail} alt={item.thumbnail}
                             style={{padding: '.2rem', objectFit: 'contain', height: '300px'}}/>
                        <Card.Content>
                            <Card.Header>{item.header}</Card.Header>
                            <Card.Meta>{convertToShowDate(item.deadline)}</Card.Meta>
                            <Card.Description>
                                {item.description}
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <p>{item.currentDonationAmount}€ von {item.goal}€ bisher gesammelt</p>
                            <Progress size={'small'} indicating style={{marginTop: '1rem', marginBottom: '1rem'}}
                                      progress={'value'} value={item.currentDonationAmount}
                                      total={item.goal}/>
                        </Card.Content>
                        <Card.Content extra>
                            <Button circular fluid className={"call-to-action-button"}
                                    onClick={() => {
                                        history.push('/spendensammlung/' + item.id)
                                    }}>
                                MEHR ERFAHREN
                            </Button>
                        </Card.Content>
                    </Card>)
                }
            })
            if (projects.length > 0) {
                return (
                    <Card.Group centered itemsPerRow={applicationWidth <= 787 ? 1 : 3}>
                        {projects}
                    </Card.Group>
                )
            } else {
                return (
                    <div>
                        <Message>
                            <Message.Header>Momentan gibt es keine aktiven SpendenProjekte</Message.Header>
                            <p>
                                Leider haben wir momentan keine Projekte für die Sie spenden können.
                            </p>
                        </Message>
                    </div>
                )
            }
        }

        const showCategories = () => {
            return categories.map((category, index) => {
                return <div className={'five wide computer eight wide tablet sixteen wide mobile column'}
                            key={index + " Cat"}>
                    <Button circular fluid
                            style={{"marginBottom": "20px"}}
                            className={category.active ? "call-to-action-button-blue" : "inactive-cat-button"}
                            onClick={() => {
                                let newCategoryState = categories;
                                if (index === 0 && !categories[0].active) {
                                    for (let i = 1; i < categories.length; i++) {
                                        newCategoryState[i].active = false;
                                    }
                                } else {
                                    newCategoryState[0].active = false;
                                }
                                newCategoryState[index].active = !newCategoryState[index].active;
                                setCategories([...newCategoryState]);
                                setProjects([...Projects.filter(filter)])
                            }}>
                        {category.name.toUpperCase()}
                    </Button>
                </div>
            })
        }

        return (
            <div className={'grid ui'}>
                <TitleImageComponent
                    textStore={textStore}
                    namespace={namespace}
                    imageLinkId={`${namespace}-title-image-id`}
                    headerId={`${namespace}-title-header-id`}
                    subheaderId={`${namespace}-subtitle-header-id`}
                />
                <div className={'row centered'}>
                    <div className={'fourteen wide computer sixteen wide tablet mobile column'}>
                        <Breadcrumb className={"breadcrumb-container"}>
                            <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                                history.push("/");
                            }}>
                                HOME</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section
                                className={"active-breadcrumb nav-item-green"}>SPENDENSAMMLUNG</Breadcrumb.Section>
                        </Breadcrumb>
                    </div>
                </div>
                {Projects.length > 0 ?
                    <>
                        <div className={'row centered'}>
                            <div className={'twelve wide computer fourteen wide tablet sixteen wide mobile column'}>
                                <p className={"text-block"}>
                                    <EditHTMLComponent textStore={textStore} id={`${namespace}-sector-1-text-id`}
                                                              namespace={namespace}/>
                                </p>
                            </div>
                        </div>
                        <div className={'row centered'}>
                            <div className={'twelve wide column computer'}>
                                {showProjects()}
                            </div>
                        </div>
                        <div className={'row centered'}>
                            {currentAmount < showProjects.length - 1 ?
                                <div className={'five wide computer eight wide tablet ten wide mobile column'}>
                                    <Button circular fluid className={"call-to-action-button"}
                                            onClick={() => {
                                                setCurrentAmount(currentAmount + 3);
                                            }}>
                                        MEHR ANZEIGEN
                                    </Button>
                                </div>
                                :
                                null
                            }
                        </div>
                    </>
                    : null}

                <div className={'row centered'}>
                    <div className={'twelve wide computer tablet sixteen wide mobile column text-align--center'}>
                        <DonatePage location={search} id={'donate-anchor'}/>
                    </div>
                </div>
            </div>)
    }
))
export default FundraisingPage;