import React from "react";
import PropTypes from "prop-types";
import {observable} from "mobx";
import userStore from "../../stores/userStore"
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import {fetchTextById} from "../../../server/helper/ssrHelper";
import {createText, updateText} from "../../helper/util";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";


class EditTextComponent extends React.Component {
    //Edit language: only to change language in Edit state
    @observable language = "de-DE";

    constructor(props) {
        super(props);
        this.state = {
            textStore: this.props.textStore,
            editState: false,
            text: ""
        }
        if (typeof this.props.textStore[this.props.id] === "undefined" || this.props.textStore === {}) {
            fetchTextById(this.props.id).then(text => {
                if (typeof text !== "undefined") {
                    this.setState({...this.state, textStore: {[this.props.id]: text[this.props.id]}})
                }
            })
        }
    }

    changeText(event) {
        this.setState({...this.state, text:event.target.value})
    }

    render() {
        let {id, style} = this.props;
        let textStore = this.state.textStore;
        let language = "de-DE";
        const displayType = userStore.userFromServer !== null ? "admin" : "user";
        let contentToShow = "";
        if (displayType === "admin") {
            let textToEdit = {content: ""};
            if (typeof textStore[id] !== "undefined") {
                if (typeof textStore[id][language] !== "undefined") {
                    textToEdit = textStore[id][this.language];
                } else {
                    textToEdit = {};
                }
            }

            if (this.state.editState) {
                if (style === null) {
                    style = {
                        "zIndex": 1,
                        "position": "relative",
                        "backgroundColor": "white",
                        "overflow": "auto"
                    };
                } else {
                    // need to copy this because otherwise I get "not extendable error"
                    let customStyle = Object.assign({}, style);
                    customStyle.zIndex = 1;
                    customStyle.position = "relative";
                    customStyle.backgroundColor = "white";
                    customStyle.overflow = "auto";
                    style = customStyle;
                }

                contentToShow = <div style={style}>
                    <Form>
                        <Form.Field>
                            <TextArea style={{height: "150px", width: "100%"}}
                                      name='htmlText'
                                      onChange={this.changeText.bind(this)}
                                      value={typeof this.state.text === "undefined" ? "" : this.state.text}/>
                        </Form.Field>
                        <Form.Field>
                            <Button.Group>
                                <Button color="green" onClick={() => {

                                    textToEdit = {
                                        content: this.state.text,
                                        title: "",
                                        description: ""
                                    };


                                    if (typeof textStore[id] === "undefined" || typeof textStore[id][this.language] === "undefined") {
                                        createText(id, textToEdit, this.language, this.props.namespace).then(isSuccess => {
                                            if (isSuccess) {
                                                fetchTextById(id).then(text => {
                                                    textStore[id] = text[id]
                                                  this.setState({...this.state, editState: false})
                                                })
                                            }
                                        }).catch((error) => {
                                            console.log(error)
                                        });
                                    } else {
                                        updateText(id, textToEdit, this.language, this.props.namespace).then(isSuccess => {
                                            if (isSuccess) {
                                                fetchTextById(id).then(text => {
                                                    textStore[id] = text[id]
                                                    this.setState({...this.state, editState: false})
                                                })
                                            }
                                        }).catch((error) => {
                                            console.log(error)
                                        });
                                    }
                                }}>
                                    Speichern
                                </Button>
                                <Button color="red" onClick={() =>   this.setState({...this.state, editState: false})}>Abbrechen</Button>
                            </Button.Group>
                        </Form.Field>
                    </Form>
                </div>
            } else {

                contentToShow = <div style={style}>
                    <div id={id} dangerouslySetInnerHTML={{__html: textToEdit.content}}/>
                    {textToEdit.isNamespace === this.props.namespace ? "" :
                        <Label size={"tiny"} className={"namespace-note"} style={{opacity: "80%"}} floating
                               color={"red"}>!</Label>}
                    <br/>
                    <Button color="orange" icon="edit outline" onClick={() => {
                        this.setState({...this.state, editState: true, text: textToEdit.content})
                    }}/>
                </div>
            }
        } else {
            let textToShow = {content: ""};

            if (typeof textStore[id] !== "undefined") {
                if (typeof textStore[id][language] !== "undefined") {
                    textToShow = textStore[id][language];
                } else {
                    textToShow = {};
                }
            }
            contentToShow = <div>
                <div style={style} id={id} >{textToShow.content}</div>
            </div>;
        }
        return (
            contentToShow
        );
    }
}

EditTextComponent.propTypes = {
    textStore: PropTypes.object,
    id: PropTypes.string,
    style: PropTypes.object,
    namespace: PropTypes.string,
};

EditTextComponent.defaultProps = {
    textStore: {},
    id: Math.random().toString(),
    style: null,
    namespace: null
};

export default EditTextComponent