import React from "react";
import {withTranslation} from "react-i18next";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";

class LoaderComponent extends React.Component {

    render() {
        const {t} = this.props;

        return (
            <div className="content-container">
                <Loader inverted content={t("loading")}/>
            </div>
        );
    }
}
export default withTranslation()(LoaderComponent)