import config from "../config/main.config"
import userStore from "../stores/userStore"
import {useEffect, useState} from "react";

export function isDevelop() {
    return process.env.NODE_ENV === 'development' || typeof process.env.NODE_ENV === 'undefined';
}


export function isAdmin() {
    if (getTokenFromLocalStorage() !== null && typeof getTokenFromLocalStorage() !== "undefined") {
        return true;
    } else if (userStore.user !== null) {
        return true;
    }
    return false;
}


export function getTokenFromLocalStorage() {
    if (typeof window !== "undefined") {
        let object = JSON.parse(localStorage.getItem(config.STORAGE_KEY));

        if (object === null) {
            return null;
        }

        let oneDayAfterTokenDate = new Date(object.timestamp);
        oneDayAfterTokenDate.setDate(oneDayAfterTokenDate.getDate() + 1);

        if (oneDayAfterTokenDate.getTime() > new Date().getTime()) {
            return object.token;
        } else {
            removeTokenFromStorage();
            return null;
        }
    }
}

export function removeTokenFromStorage() {
    if (typeof window !== "undefined") {
        localStorage.removeItem(config.STORAGE_KEY);
        localStorage.removeItem(config.USER_STORAGE_KEY);
    }
}

export function setTokenLocalStorage(token) {
    if (typeof window !== "undefined") {
        let object = {
            "token": token,
            timestamp: new Date().getTime()
        };

        localStorage.setItem(config.STORAGE_KEY, JSON.stringify(object));
    }
}


export function setUserToLocalStorage(user) {
    if (typeof window !== "undefined") {
        let object = {
            "user": user,
            timestamp: new Date().getTime()
        };

        localStorage.setItem(config.USER_STORAGE_KEY, JSON.stringify(object));
    }

}


export function getUserFromLocalStorage() {
    if (typeof window !== "undefined") {
        let object = JSON.parse(localStorage.getItem(config.USER_STORAGE_KEY));

        if (object === null) {
            return null;
        }

        let oneDayAfterTokenDate = new Date(object.timestamp);
        oneDayAfterTokenDate.setDate(oneDayAfterTokenDate.getDate() + 1);

        if (oneDayAfterTokenDate.getTime() > new Date().getTime()) {
            return object.user;
        } else {
            removeTokenFromStorage();
            return null;
        }
    }
    return null;
}


export function createText(id, textToCreate, language, namespace) {
    return fetch(config.BASE_URL + "texts", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        },
        body: JSON.stringify({
            frontendId: id,
            content: textToCreate.content,
            title: textToCreate.title,
            description: textToCreate.description,
            language: language,
            namespace: namespace,
        })
    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    addObjectToTexts(json);
                });
                return true;
            } else if (response.status === 401) {
                alert("Bitte melden Sie sich an");
            } else {
                alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
                return false;
            }
        })
        .catch(
            error => {
                alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
                return false;
            }
        );
}

function addObjectToTexts(textObject) {
    let tempTexts = {}
    if (typeof tempTexts[textObject.frontendId] === "undefined") {
        tempTexts[textObject.frontendId] = {};
    }

    tempTexts[textObject.frontendId][textObject.language] = {
        content: textObject.content,
        title: textObject.title,
        description: textObject.description
    };
    return tempTexts
}

export function updateText(id, textToUpdate, language, namespace) {
    return fetch(config.BASE_URL + "texts", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        },
        body: JSON.stringify({
            frontendId: id,
            content: textToUpdate.content,
            title: textToUpdate.title,
            description: textToUpdate.description,
            language: language,
            namespace: namespace
        })
    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                addObjectToTexts({
                    frontendId: id,
                    content: textToUpdate.content,
                    title: textToUpdate.title,
                    description: textToUpdate.description,
                    language: language
                });

                return true;
            } else if (response.status === 401) {
                alert("Bitte melden Sie sich an");
            } else {
                alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
                return false;
            }
        })
        .catch(
            error => {
                alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
                return false;
            }
        );
}

export function getWindowSize() {
    if (typeof window !== "undefined") {
        if (window.addEventListener) {
            window.addEventListener('resize', function () {
            });
        }
        return window.innerWidth
    }

}

export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    const [windowSize, setWindowSize] = useState({width: undefined, height: undefined});
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });

        }

        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}


export function getTimespanToShow(timespan) {
    return timespan.startDate.getDate() + "." + timespan.startDate.getMonth() + "." + timespan.startDate.getFullYear() + " - " +
        timespan.endDate.getDate() + "." + timespan.endDate.getMonth() + "." + timespan.endDate.getFullYear();
}

