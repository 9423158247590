import config from "../../config/main.config"
import {getTokenFromLocalStorage} from "../../helper/util";


export {
    createProject,
    deleteProject,
    getAllProjects,
    editProject,
    getProjectById,
    updateDonationAmount,
    getAllActiveProjects
}

function updateDonationAmount(id, totalAmount) {
    return new Promise(resolve => fetch(config.BASE_URL + "fundraising/updateDonationAmount/" + id, {
        method: 'PUT',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        }, body: JSON.stringify({totalAmount})
    }).then(response => {
        if (response.status >= 200 && response.status <= 300) {
            return response.json().then(json => {
                return resolve(json);
            })
        }
    }))
}

function getProjectById(id) {
    return new Promise(resolve => fetch(config.BASE_URL + "fundraising/" + id, {
        method: 'GET',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        }
    }).then(response => {
        if (response.status >= 200 && response.status <= 300) {
            return response.json().then(json => {
                return resolve(json);
            })
        }
    }))
}

function editProject(project) {
    return new Promise(resolve => fetch(config.BASE_URL + "fundraising/", {
        method: 'PUT',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        }, body: JSON.stringify({project})
    }).then(response => {
        if (response.status >= 200 && response.status <= 300) {
            return response.json().then(json => {
                return resolve(json);
            })
        }
    }))
}

function getAllProjects() {
    return new Promise(resolve => fetch(config.BASE_URL + "fundraising/all", {
        method: 'GET',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        }
    }).then(response => {
        if (response.status >= 200 && response.status <= 300) {
            return response.json().then(json => {
                return resolve(json);
            })
        }
    }))
}

function getAllActiveProjects() {
    return new Promise(resolve => fetch(config.BASE_URL + "fundraising/all/active", {
        method: 'GET',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        }
    }).then(response => {
        if (response.status >= 200 && response.status <= 300) {
            return response.json().then(json => {
                return resolve(json);
            })
        }
    }))
}

function createProject(project) {
    return new Promise(resolve => fetch(config.BASE_URL + "fundraising/", {
        method: 'POST',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        }, body: JSON.stringify({project})
    }).then(response => {
        if (response.status >= 200 && response.status <= 300) {
            return response.json().then(json => {
                return resolve(json);
            })
        }
    }))
}

function deleteProject(id) {
    return new Promise(resolve => fetch(config.BASE_URL + "fundraising/" + id, {
        method: 'DELETE',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        }
    }).then(response => {
        if (response.status >= 200 && response.status <= 300) {
            return response.json().then(json => {
                return resolve(json);
            })
        }
    }))
}