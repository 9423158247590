import React from "react";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";


const NewsletterModal = (props) => {

    return (
        <Modal open={props.open} onClose={() => props.onClose()}>
            <Modal.Header>Anmeldung zum Newsletter der Bürgerstiftung Kreis Ravensburg</Modal.Header>
            <form className={"ui form"}
                  action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D09000002Z6Dn"
                  method="POST">
                <p className={"newsletter-content"}>Sie möchten regelmäßig über die Arbeit und aktuellen Projekte der
                    Bürgerstiftung Kreis Ravensburg informiert werden? Gerne senden wir Ihnen unseren Newsletter per
                    E-Mail zu.</p>
                <input type="hidden" name="oid" value="00D09000002Z6Dn"/>
                <input type="hidden" name="retURL"
                       value="https://www.buergerstiftung-kreis-rv.de/dankeseite"/>
                <div className={"newsletter-content grid"}>
                    <div className={"field display-flex justify-center gap-2"} style={{marginBottom:0}}>
                        <div className={"field w-full"}>
                            <label htmlFor="first_name">Vorname</label>
                            <input id="first_name" maxLength="40" name="first_name" size="20" type="text"
                                   placeholder="Vorname eingeben..."/>
                        </div>
                        <div className={"field w-full"}>
                            <label htmlFor="last_name">Nachname</label>
                            <input id="last_name" maxLength="80" name="last_name" size="20" type="text"
                                   placeholder="Nachname eingeben..."/>
                        </div>

                    </div>
                    <div className={"field w-full"}>
                        <label htmlFor="email">E-Mail *</label>
                        <input id="email" maxLength="80" name="email" size="20" type="email"
                               placeholder="E-Mail Adresse eingeben..." required/>
                    </div>
                    <div className={"field w-full"}>
                        <label htmlFor="company">Unternehmen/Organisation</label>
                        <input id="company" maxLength="40" name="company" placeholder="Unternehmen/Organisation eingeben..."
                               size="20" type="text"/>
                    </div>

                </div>
                <input className={"ui button call-to-action-button float-right"} style={{marginRight:"2rem", marginBottom:"1rem"}} type="submit" name="submit"/>
            </form>
        </Modal>
    )
}


export default NewsletterModal;
