import {action, observable} from 'mobx';
import config from "../config/main.config";

class TrackingStore {
    @observable tracking = [];

    constructor(stores) {
        if (typeof stores !== "undefined" && typeof stores.trackingStore !== "undefined" && typeof stores.trackingStore.tracking !== "undefined") {
            this.tracking = stores.trackingStore.tracking;
        }
    }

    @action fetchTracking() {
        return new Promise(res=>fetch(config.BASE_URL + "tracker", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.tracking = json;
                        return res(json);
                    });

                } else if (response.status === 401) {
                    this.tracking = [];
                } else {
                    this.tracking = [];
                }
            })
            .catch(
                error => {
                    alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
                    throw error;
                }
            ));
    }

    @action addVisit(link) {
        try{
        fetch(config.BASE_URL + "tracker", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({
                link: link
            })
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
            }
        }).catch(
            error => {
            }
        );
        }catch(e){

        }
    }
}

export default TrackingStore;